import React, { useEffect, createContext, useReducer } from "react";
import { UserReducers } from '../reducers/UserReducers'
import { UserTypes } from "../types/UserTypes"

export const UserContext = createContext()

export function UserProvider(props) {
  const decifrado = atob(localStorage.getItem('pm-session') || '')
  const datosSession = decifrado == '' ? {} : JSON.parse(decifrado);

  const initialUserState = {
    BDEscuela: datosSession.BDEscuela,
    CodRol: datosSession.CodRol,
    FechaNacimiento: datosSession.FechaNacimiento,
    apellidoMaterno: datosSession.apellidoMaterno,
    apellidoPaterno: datosSession.apellidoPaterno,
    celular: datosSession.celular,
    codEmpresa: datosSession.codEmpresa,
    codUsuario: datosSession.codUsuario,
    direccion: datosSession.direccion,
    email: datosSession.email,
    empresa: datosSession.empresa,
    foto: datosSession.foto,
    nomUsuario: datosSession.nomUsuario,
    nombre: datosSession.nombre,
    tiempoExpiraMiliSegundos: datosSession.tiempoExpiraMiliSegundos,
    token: datosSession.token
  }

  const [ state, dispatch ] = useReducer(UserReducers, initialUserState)

  const signIn = (objSession) => {
    var cifrado = btoa(JSON.stringify(objSession));
    localStorage.setItem('pm-session', cifrado);
    dispatch({type: UserTypes.SIGN_IN, payload: objSession})
  }
  const signOut = () => {
    localStorage.clear();
    dispatch({type: UserTypes.SIGN_OUT})
  }

  return (
    <UserContext.Provider value={{stateUser: state, signIn, signOut}}>
      {props.children}
    </UserContext.Provider>
  )
}