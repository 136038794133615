import { useEffect, useState, useRef } from 'react';
import { Modal } from '../../components/modal/Modal';
import { useVehiculos } from '../../../hooks/useVehiculos';
import autoDefault from '../../../assets/images/autoDefault.jpg';
import { useTiposVehiculos } from '../../../hooks/useTiposVehiculos';
import { Cabecera } from '../../components/utils/Cabecera';
import { Tabs, Tab, Box, Button } from '@mui/material';
import { CustomTextField } from '../../components/forms/CustomTextField';
import { CustomImage } from '../../components/forms/CustomImagen';
import { CustomSelect } from '../../components/forms/CustomSelect';
import { notify } from '../../../utils/utils';

export const Vehiculos = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const inputFoto = useRef();

  const { tiposVehiculos } = useTiposVehiculos();
  const fotoDefault = autoDefault;

  const {
    vehiculos,
    listarVehiculos,
    guardarEditarVehiculo,
    vehiculo, 
    editarValorVehiculo, 
    obtenerVehiculo, 
    isOpenModalRegistrar, 
    closeModalRegistrar, 
    setFotoFile,
    eliminarPersona, 
    isOpenModalEliminar, 
    openModalEliminar,
    closeModalEliminar, 
    vehiculoEliminar,
    fotoUrlVehiculo,
    setFotoUrlVehiculo, 
    guardarVehiculoFoto
  } = useVehiculos()
  
  const [filteredVehiculos, setFilteredVehiculos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!selectedFile) {
      setFotoUrlVehiculo(fotoDefault);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setFotoUrlVehiculo(objectUrl || fotoDefault);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = async e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(fotoDefault)
      return
    }
    setSelectedFile(e.target.files[0])
    setFotoFile(e.target.files[0])
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredVehiculos(vehiculos);
    } else {
      const filtered = vehiculos.filter(vehiculo =>
          ['Placa', 'Marca', 'Modelo', 'Categoria', 'Combustible', 'Direccion', 'Usuario', 'NombreTipoVehiculo']
          .some(key => 
              vehiculo[key] && typeof vehiculo[key] === 'string' && vehiculo[key].toLowerCase().includes(searchTerm)
          )                
      );
      setFilteredVehiculos(filtered);
    }
  }, [searchTerm, vehiculos]);

  const filtros = [
    { nombre: 'Combustible', label: 'Combustible' },
    { nombre: 'Marca', label: 'Marca' }
  ];

  return (
    <>
      <Cabecera
        titulo="Vehículos"
        onNuevo={() => obtenerVehiculo()}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        data={vehiculos}
        updateFilteredData={setFilteredVehiculos}
        searchFields={['Placa', 'Marca', 'Modelo', 'Categoria', 'Combustible', 'Direccion', 'Usuario', 'NombreTipoVehiculo']}
        placeholder="Buscar en vehículos..."
        nuevoTexto="Nuevo vehículo"
        filtros={filtros}
      />
      <div className="containerScroll mb-10 mt-10 relative top-0 bottom-0 left-0 right-0">
        <div className="">
          <ul className="cards">
            {filteredVehiculos?.length > 0 ? filteredVehiculos?.map((vehiculo, i) => (
              <li className='cursor-pointer' key={i}>
                <img src={vehiculo.Foto} className='rounded' alt='Vehículo' />
                <div className="details ">
                  <span><i className='fas fa-car mr-2' />{vehiculo.Marca} {vehiculo.Modelo} {vehiculo.Año}</span>
                  <span><i className='fa fa-imdb mr-2' />{vehiculo.Placa}</span>
                  <span><i className='fas fa-car-side mr-2' />{vehiculo.NombreTipoVehiculo}</span>
                  <span><i className='fas fa-gas-pump mr-2' />{vehiculo.Combustible}</span>
                  <div className='flex'>
                    <Button 
                      onClick={() => obtenerVehiculo(vehiculo.CodVehiculo)} 
                      variant="contained" 
                      color="primary" 
                      style={{ marginRight: '10px' }}
                    >
                      <i className="fas fa-edit mr-1"></i> Editar
                    </Button>
                    <Button 
                      onClick={openModalEliminar} 
                      variant="contained" 
                      color="error"
                    >
                      <i className="fas fa-trash mr-1"></i> Eliminar
                    </Button>
                  </div>
                </div>
              </li>
            )) : (
                <div className="text-center">No hay información que mostrar con los parámetros seleccionados.</div>
            )}
          </ul>
        </div>
      </div>

      <Modal 
        isOpen={isOpenModalRegistrar} 
        closeModal={closeModalRegistrar}
        action={() => guardarVehiculoFoto()}
        title={`${vehiculo.CodVehiculo === 0 ? 'Registrar Unidad' : 'Editar Unidad'}`}
      >
        <div className="flex bg-gray-900 text-white p-4 rounded-lg lg:max-h-[500px] lg:overflow-y-auto">
          <div className="flex-shrink-0 pr-8">
            <CustomImage
              imageUrl={fotoUrlVehiculo || fotoDefault}
              onImageChange={onSelectFile}
              estilo={'cuadrado'}
              onClick={() => inputFoto.current.click()}
            />
            <div className="flex items-center gap-1 w-full mt-2 ">
              <CustomTextField
                label="Placa"
                value={vehiculo.Placa || ''}
                onChange={(e) => editarValorVehiculo("Placa", e.target.value)}
              />
              <button onClick={() => notify("No se pudo obtener informacion de la placa", 'info')} className=" hover:bg-green-500  bg-green-600 p-2 rounded px-2 ml-2">
                <i className="fa fa-search text-white"></i>
              </button>
            </div>
          </div>

          <div className="flex-grow">

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                indicatorColor="secondary"
                sx={{
                  "& .MuiTab-root": {
                    color: "#DEDEDE",
                  },
                  "& .Mui-selected": {
                    color: "#FFFFFF",
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#FFFFFF",
                  }
                }}
              >
                            
                <Tab label="General" />
                <Tab label="Datos Técnicos" />
                <Tab label="Características" />
              </Tabs>
            </Box>

            {/* Contenido de los Tabs en Tres Columnas */}
            <Box sx={{ paddingTop: 2, height: 250, overflowY: 'auto' }}>
              <div className="grid grid-cols-3 gap-4">
                {tabIndex === 0 && (
                  <>
                    <CustomTextField
                      label="Marca"
                      value={vehiculo.Marca || ''}
                      onChange={(e) => editarValorVehiculo("Marca", e.target.value)}
                    />
                        
                    <CustomTextField
                      label="Año"
                      value={vehiculo.Año || ''}
                      onChange={(e) => editarValorVehiculo("Año", e.target.value)}
                    />

                    <CustomTextField
                      label="Tarjeta de Propiedad"
                      value={vehiculo.TarjetaPropiedad || ''}
                      onChange={(e) => editarValorVehiculo("TarjetaPropiedad", e.target.value)}
                    />
                    <CustomSelect
                      label="Tipo de Vehículo"
                      value={vehiculo.CodTipoVehiculo || ''}
                      onChange={(e) => editarValorVehiculo("CodTipoVehiculo", e.target.value)}
                      options={tiposVehiculos.map(vehiculo => ({
                        value: vehiculo.CodTipoVehiculo,
                        label: vehiculo.NombreTipoVehiculo
                      }))}
                    />
                  </>
                )}
                {tabIndex === 1 && (
                  <>
                    <CustomTextField
                      label="Combustible"
                      value={vehiculo.Combustible || ''}
                      onChange={(e) => editarValorVehiculo("Combustible", e.target.value)}
                    />
                    <CustomTextField
                      label="Serie"
                      value={vehiculo.Serie || ''}
                      onChange={(e) => editarValorVehiculo("Serie", e.target.value)}
                    />
                    <CustomTextField
                      label="Color"
                      value={vehiculo.Color || ''}
                      onChange={(e) => editarValorVehiculo("Color", e.target.value)}
                    />
                  </>
                )}
                                
                {tabIndex === 2 && (
                  <>
                    <CustomTextField
                      label="Asientos"
                      value={vehiculo.Asientos || ''}
                      onChange={(e) => editarValorVehiculo("Asientos", e.target.value)}
                    />
                    <CustomTextField
                      label="Cilindros"
                      value={vehiculo.Cilindros || ''}
                      onChange={(e) => editarValorVehiculo("Cilindros", e.target.value)}
                    />
                    <CustomTextField
                      label="Puertas"
                      value={vehiculo.Puertas || ''}
                      onChange={(e) => editarValorVehiculo("Puertas", e.target.value)}
                    />
                    <CustomTextField
                      label="Carga Util"
                      value={vehiculo.CargaUtil || ''}
                      onChange={(e) => editarValorVehiculo("CargaUtil", e.target.value)}
                    />
                    <CustomTextField
                      label="Longitud"
                      value={vehiculo.Longitud || ''}
                      onChange={(e) => editarValorVehiculo("Longitud", e.target.value)}
                    />
                    <CustomTextField
                      label="Altura"
                      value={vehiculo.Altura || ''}
                      onChange={(e) => editarValorVehiculo("Altura", e.target.value)}
                    />
                    <CustomTextField
                      label="Pasajeros"
                      value={vehiculo.Pasajeros || ''}
                      onChange={(e) => editarValorVehiculo("Pasajeros", e.target.value)}
                    />
                    
                    <CustomTextField
                      label="Ejes"
                      value={vehiculo.Ejes || ''}
                      onChange={(e) => editarValorVehiculo("Ejes", e.target.value)}
                    />
                  </>
                )}
              </div>
            </Box>
          </div>
        </div>
      </Modal>
    </>
  );
};
