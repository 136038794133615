import React, { useEffect, useState, useRef, useContext } from 'react';
import { UserContext } from '../../../context/provider/UserProvider';
import { useTipoClases } from '../../../hooks/useTipoClases';
import { useMatricula } from '../../../hooks/useMatricula';
import { useUsuarios } from '../../../hooks/useUsuarios';
import { useTiposVehiculos } from '../../../hooks/useTiposVehiculos';
import LinesChart from '../../components/LinesChart';

export const ReporteMatriculas = () => {
    
    const {
        usuarios,
    } = useUsuarios()

    const usuariosEstudiantes = usuarios.filter(u => u.CodRol == 4);


    const {
        tiposVehiculos
    } = useTiposVehiculos()

    const {
        matricula,
        listarMatriculas,
        guardarEditarMatricula,
        matriculas,
        editarValorMatricula,
        obtenerMatricula,
        spinner,
        isOpen,
        closeModal,
        openModal
    } = useMatricula()

    const {
        tipoClase,
        listarTipoClases,
        guardarEditarTipoClase,
        tipoClases,
        editarValorTipoClase,
        obtenerTipoClase,
    } = useTipoClases()

    const formatearFecha = (fechaHoraRecibida) =>{
        const fechaHora = new Date(fechaHoraRecibida);
        const nuevaFechaHoraFormateada = fechaHora.toLocaleString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // Usa formato de 24 horas
        });
        return nuevaFechaHoraFormateada
    }

    const [ fechaPago, setFechapago ] = useState( new Date())
    const [ fechaMatricula, setFechaMatricula ] = useState( new Date())
    const classNameInput = "text-gray-800 h-[30px] rounded px-4 w-full  text-l border focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const { stateUser }  = useContext(UserContext)

    const [ registrarEditar,setRegistrarEditar ] = useState(true)


    useEffect(()=>{
        editarValorMatricula("FechaPago", fechaPago)
        editarValorMatricula("FechaMatricula", fechaMatricula)
    },[fechaMatricula, fechaPago])

    return (
        <>
            <h1 className=' text-2xl underline mt-5 ml-5'>Reporte de Matriculas</h1>
            <div className="containerScroll  h-[40%] mt-2  relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
                <table id='tabla-personas' className="tableResponsiveReporte ">
                    <thead>
                        <tr>
                            <th className="w-[30px]">N</th>
                            <th className="text-center">FECHA</th>
                            <th className="text-center">PAQUETE</th>
                            {/* <th className="text-center">HORAS</th> */}
                            <th className="text-cemter">ESTUDIANTE</th>
                            <th className="text-center">MONTO</th>
                            <th className="text-center">PAGADO</th>
                            <th className="w-[100px]">ATENCIÓN</th>
                            {/* <th className="w-[100px]">COMENTARIO</th> */}
                            {/* <th className="w-[100px]"></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {   
                            matriculas.length > 0 
                            ? matriculas.map((m, i) => {
                                return (
                                    <tr key={m.CodMatricula}>
                                        <td>{ i + 1}</td>
                                        <td className="text-center">{formatearFecha(m.FechaMatricula)}</td>
                                        <td className="text-center">{m.NombreTipoClase}</td>
                                        {/* <td className="text-center">{m.CantidadHoras}</td> */}
                                        <td className=" text-center">{m.Estudiante}</td>
                                        <td className="text-center">S/ {m.Monto}</td>
                                        <td className="text-center">S/ {m.MontoPagado}</td>
                                        <td className=" text-center">{m.UsuarioAtencion}</td>
                                        {/* <td className="text-center">{m.ComentarioMatricula}</td> */}

                                        
                                    </tr>
                                )
                            })
                            : <tr><td colSpan="9" className="text-center">No hay información que mostrar con los parámetros seleccionados.</td></tr>
                        }
                    </tbody>
                </table>
            </div>

            <div className='flex'>
                <div className={`m-2 h-[90px] text-white rounded-lg bg-slate-800 shadow-md w-full p-2 relative flex flex-col items-right`}>
                    <div className=" mt-2 text-right">
                        <p className={`text-3xl font-bold`}>{matriculas?.length}</p>
                        <p className="text-xl text-gray-400 border-t border-gray-500">
                            Total Matriculas
                        </p>
                    </div>
                </div>
                <div className={`m-2 h-[90px] text-white rounded-lg bg-slate-800 shadow-md w-full p-2 relative flex flex-col items-right`}>
                    <div className=" mt-2 text-right">
                        <p className={`text-3xl font-bold`}> S/. {0}</p>
                        <p className="text-xl text-gray-400 border-t border-gray-500">
                            Monto Total
                        </p>
                    </div>
                </div>
                <div className={`m-2 h-[90px] text-white rounded-lg bg-slate-800 shadow-md w-full p-2 relative flex flex-col items-right`}>
                    <div className=" mt-2 text-right">
                        <p className={`text-3xl font-bold`}> {0}</p>
                        <p className="text-xl text-gray-400 border-t border-gray-500">
                            Pagos Completos
                        </p>
                    </div>
                </div>
                <div className={`m-2 h-[90px] text-white rounded-lg bg-slate-800 shadow-md w-full p-2 relative flex flex-col items-right`}>
                    <div className=" mt-2 text-right">
                        <p className={`text-3xl font-bold`}> {'usuario'}</p>
                        <p className="text-xl text-gray-400 border-t border-gray-500">
                            Mayor Matriculas
                        </p>
                    </div>
                </div>
            </div>
                

                

            <div className='h-[30%] w-full flex'>
                <LinesChart></LinesChart>
                {/* <LinesChart></LinesChart> */}
            </div>

            
        </>
    )


}