import React, { useState,useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { Modal } from '../../components/modal/Modal';
import { useModal } from '../../../hooks/useModal';
import { useVehiculos } from '../../../hooks/useVehiculos';
import { useClases } from '../../../hooks/useClases';
import { useUsuarios } from '../../../hooks/useUsuarios';
import { useMatricula } from '../../../hooks/useMatricula';
import { useTiposVehiculos } from '../../../hooks/useTiposVehiculos';
import { notify } from '../../../utils/utils';
import { useTipoClases } from '../../../hooks/useTipoClases';
import { Cabecera } from '../../components/utils/Cabecera';
import { CustomSelect } from '../../components/forms/CustomSelect';
import { CustomTextField } from '../../components/forms/CustomTextField';
import { CustomImage } from '../../components/forms/CustomImagen';
import { CustomAutocomplete } from '../../components/forms/CustomAutocomplete';
import { Avatar, InputAdornment } from '@mui/material';

export const Programacion = () => {
    const { usuarios,listarUsuarios } = useUsuarios()
    const { matricula, listarMatriculas, matriculas, obtenerMatricula, } = useMatricula()
    const { vehiculos, listarVehiculos, vehiculo, obtenerVehiculo,} = useVehiculos()
    const { tiposVehiculos } = useTiposVehiculos()
    const { clases, listarClases, guardarEditarClase, clase, editarValorClase, obtenerClase, spinner,isOpenModalProgramacion , openModalProgramacion, closeModalProgramacion } = useClases()
    const { tipoClases } = useTipoClases()
    const [ numeroHora, setNumeroHora ] = useState(0)
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const usuariosEstudiantes = usuarios.filter(u => u.CodRol == 4); //4 codEstudiante
    const usuariosInstructores = usuarios.filter(u => u.CodRol == 3); //3 codInstructor
    const [selectedDate, setSelectedDate] = useState(today.toISOString().slice(0, 10));
    const [ fechaHoraInicioProgramado, setFechaHoraInicioProgramado ] = useState(new Date(selectedDate))
    const [ fechaHoraFinProgramado, setFechaHoraFinProgramado ] = useState( new Date(selectedDate))
    const [ eventosCalendario, setEventosCalendario ] = useState([])

    const [ tipoVehiculoSeleccionado, setTipoVehiculoSeleccionado ] = useState(100)
    const [ vehiculosFiltrados, setVehiculosFiltrados ] = useState(vehiculos)
    const [ estudianteSeleccionado, setEstudianteSeleccionado ] = useState('')
    const [ matriculaFiltrada,setMatriculaFiltrada ] = useState({})
    const [ tipoClaseFiltrado, setTipoClaseFiltrado ] = useState({})
    const [ noHayMatricula, setNoHayMatricula ] = useState(false)
    const [ verCalendario, setVerCalendario ] = useState(true)
    const classNameInput = "text-gray-800 h-[30px] rounded px-4 w-full  text-l border focus:outline-none focus:ring-1 focus:ring-blue-500"   
  const [selectedStudent, setSelectedStudent] = useState(null);

    const [eventTitle, setEventTitle] = useState('');
    const [eventTime, setEventTime] = useState('');


    const getEventCountForDate = (date) => {
        return clases.filter(evento => {
            const eventDate = new Date(evento.FechaHoraInicioProgramado).toISOString().slice(0, 10);
            return eventDate === date;
        }).length;
    };

    useEffect(()=>{ 
        editarValorClase("NumeroHora", numeroHora)
    },[numeroHora])


    useEffect(()=>{
        console.log("estudianteSeleccionado-> ",estudianteSeleccionado)

        const matriculasFiltradas = matriculas.filter(m => m.Estudiante === estudianteSeleccionado);

        if(matriculasFiltradas?.length === 0 && estudianteSeleccionado ){
            setNoHayMatricula(true)
            notify("No se encontró una matricula activa para el estudiante",'error')
        }else{
            setNoHayMatricula(false)
        }

        const matriculaParaAsignar = matriculasFiltradas?.length > 0 ? matriculasFiltradas[0] : null;

        setMatriculaFiltrada(matriculaParaAsignar)
        console.log("matriculaFiltrada-> ",matriculasFiltradas?.length > 0 ? matriculasFiltradas[0] : null)
        
        setTipoClaseFiltrado(tipoClases?.filter(tc => tc?.CodTipoClase == matriculaParaAsignar?.CodTipoClase)[0]);
        editarValorClase("CodMatricula",matriculaParaAsignar?.CodMatricula)
        console.log("TipoClaseFiltrado -> ", tipoClases?.filter(tc => tc?.CodTipoClase == matriculaParaAsignar?.CodTipoClase)[0])
        setTipoVehiculoSeleccionado(tipoClases?.filter(tc => tc?.CodTipoClase == matriculaParaAsignar?.CodTipoClase)[0]?.CodTipoVehiculo)
    },[estudianteSeleccionado])

    useEffect(()=>{
        if(matriculaFiltrada){
            setNumeroHora((matriculaFiltrada?.CantidadHoras - matriculaFiltrada?.CantidadHorasRestantes) + 1)
        }else{
            setNumeroHora(0)
        }
    },[matriculaFiltrada])
    

    // Opciones de estilo para el calendario
    const eventRender = ({ event, el }) => {
        const eventDate = new Date(event.start).toISOString().slice(0, 10);
        const eventCount = getEventCountForDate(eventDate);

        if (eventCount >= 10) {
            el.classList.add('many-events');
        } else if (eventCount >= 3 && eventCount < 10) {
            el.classList.add('medium-events');
        } else if (eventCount === 1 || eventCount === 2) {
            el.classList.add('few-events');
        }
    };
     // Establece los eventos de FullCalendar
    const calendarEvents = eventosCalendario.map(evento => ({
        id: evento.id,
        title: evento.title,
        start: evento.start,
        end: evento.end,
        extendedProps: evento.extendedProps,
    }));
    useEffect(()=>{
        console.log("VEHICULOS -> ", vehiculos)
        if (tipoVehiculoSeleccionado == 0) {
            setVehiculosFiltrados(vehiculos);
        } else {
            setVehiculosFiltrados(vehiculos.filter(v => v.CodTipoVehiculo == tipoVehiculoSeleccionado));
        }
    },[tipoVehiculoSeleccionado])

    useEffect(()=>{
        console.log("CAMBIO Y ACTUALIZO EL SELECTED DATE -> ", selectedDate)
        setFechaHoraInicioProgramado(selectedDate)
        setFechaHoraFinProgramado(selectedDate)
    },[selectedDate])


    useEffect(()=>{
        editarValorClase("FechaHoraInicioProgramado", fechaHoraInicioProgramado)
        editarValorClase("FechaHoraFinProgramado", fechaHoraFinProgramado)
    },[fechaHoraInicioProgramado,fechaHoraFinProgramado])


    useEffect(() => {
        console.log("CARGAR EVNETOS-> ", clases)
        const eventosFormateados = clases.map(clase => ({
            id: clase.CodClase,
            title: `${clase?.TipoClase || clase.Estado}`,
            start: clase.FechaHoraInicioProgramado,
            end: clase.FechaHoraFinProgramado,
            extendedProps: {
            instructor: clase.Instructor,
            tipoClase: clase.NombreTipoClase,
            lugarInicio: clase.LugarInicio,
            lugarFin: clase.LugarFin,
            }
        }));
        console.log("eventosFormateados->",eventosFormateados)
        setEventosCalendario(eventosFormateados);
    }, [clases]);



    const handleDateClick = (arg) => {
        setSelectedDate(arg.dateStr);
    };

    const renderEventContent = (eventInfo) => {
        console.log("renderEventContent-> ",eventInfo)
        return (
            <>
                {/* <span>{eventInfo.event.HoraInicio}</span> */}
                {/* <span>{eventInfo.timeText} - {eventInfo.event.title}</span> */}
                <div> {eventInfo.event.extendedProps.tipoClase}</div>
                {/* <div>Inicio: {eventInfo.event.extendedProps.lugarInicio}, Fin: {eventInfo.event.extendedProps.lugarFin}</div> */}

                {/* Puedes añadir más detalles aquí, como instructor, estudiante, etc. */}
                {/* Asegúrate de que estos detalles estén incluidos en la propiedad `extendedProps` del evento */}
            </>
        );
    };


    // La lógica para filtrar y mostrar eventos según la fecha seleccionada puede permanecer igual
    // const eventsForSelectedDate = clases.filter(c => c.FechaHoraInicioProgramado == selectedDate);

    const eventsForSelectedDate = clases.filter(evento => {
        // Convertir la fecha del evento al formato YYYY-MM-DD para comparación
        const eventDate = new Date(evento.FechaHoraInicioProgramado).toISOString().slice(0, 10);
        return eventDate === selectedDate;
    });


    const handleHoraInicioChange = (e) => {
        const horaInicio = e.target.value; // "HH:MM"
        setFechaHoraInicioProgramado(prevState => {
            // prevState es la fecha seleccionada actual en formato "YYYY-MM-DD"
            const fechaHoraInicioString = `${selectedDate}T${horaInicio}:00`;
            // Crea un objeto Date directamente desde la cadena, asumiendo zona horaria local
            const fechaHoraInicio = new Date(fechaHoraInicioString);
    
            console.log("nueva FechaHoraInicio con manejo directo de string -> ", fechaHoraInicio);
            editarValorClase("FechaHoraInicioProgramado", fechaHoraInicio);
            return fechaHoraInicio;
        });
    };
    
    const handleHoraFinChange = (e) => {
        const horaFin = e.target.value; // "HH:MM"
        setFechaHoraFinProgramado(prevState => {
            // No es necesario usar prevState aquí porque la fecha no cambia
            const fechaHoraFinString = `${selectedDate}T${horaFin}:00`;
            // Crea un objeto Date directamente desde la cadena, asumiendo zona horaria local
            const fechaHoraFin = new Date(fechaHoraFinString);
    
            console.log("nueva FechaHoraFin con manejo directo de string -> ", fechaHoraFin);
            editarValorClase("FechaHoraFinProgramado", fechaHoraFin);
            return fechaHoraFin;
        });
    };
  const handleStudentChange = (event, value) => {
    console.log("handleStudentChange-> ", value);
    setSelectedStudent(value);
    setEstudianteSeleccionado(value.Usuario)
    editarValorClase("Estudiante", value?.Usuario || '');
  };

const data = [
  {
    range: '08:00 - 09:00',
    schedules: [
      { car: 'AAA-001', driver: 'Henry Cerna', student: 'Juan Perez', status: 'programado' },
      { car: 'AAA-002', status: 'libre' },
      { car: 'AAA-003', driver: 'Carlos Gomez', student: 'Pedro Diaz', status: 'reprogramado' },
      { car: 'AAA-004', driver: 'Maria Lopez', student: 'Ana Sanchez', status: 'programado' },
      { car: 'AAA-005', driver: 'Luis Silva', student: 'Jose Torres', status: 'completado' },
      { car: 'AAA-006', status: 'libre' },
      { car: 'AAA-007', driver: 'Roberto Diaz', student: 'Luis Gomez', status: 'completado' },
      { car: 'AAA-008', status: 'reprogramado' },
    ]
  },
  {
    range: '09:00 - 10:00',
    schedules: [
      { car: 'AAA-001', driver: 'Henry Cerna', student: 'Juan Perez', status: 'completado' },
      { car: 'AAA-002', status: 'libre' },
      { car: 'AAA-003', driver: 'Carlos Gomez', student: 'Pedro Diaz', status: 'reprogramado' },
      { car: 'AAA-004', driver: 'Maria Lopez', student: 'Ana Sanchez', status: 'programado' },
      { car: 'AAA-005', driver: 'Luis Silva', student: 'Jose Torres', status: 'libre' },
      { car: 'AAA-006', status: 'reprogramado' },
      { car: 'AAA-007', driver: 'Roberto Diaz', student: 'Luis Gomez', status: 'programado' },
      { car: 'AAA-008', driver: 'Miguel Perez', student: 'Clara Mendoza', status: 'completado' },
    ]
  },
  {
    range: '10:00 - 11:00',
    schedules: [
      { car: 'AAA-001', driver: 'Henry Cerna', student: 'Juan Perez', status: 'reprogramado' },
      { car: 'AAA-002', driver: 'Maria Lopez', student: 'Pedro Diaz', status: 'programado' },
      { car: 'AAA-003', driver: 'Carlos Gomez', student: 'Ana Sanchez', status: 'libre' },
      { car: 'AAA-004', driver: 'Luis Silva', student: 'Jose Torres', status: 'programado' },
      { car: 'AAA-005', status: 'reprogramado' },
      { car: 'AAA-006', driver: 'Roberto Diaz', student: 'Luis Gomez', status: 'completado' },
      { car: 'AAA-007', driver: 'Miguel Perez', student: 'Clara Mendoza', status: 'programado' },
      { car: 'AAA-008', status: 'completado' },
    ]
  },
  {
    range: '11:00 - 12:00',
    schedules: [
      { car: 'AAA-001', driver: 'Carlos Gomez', student: 'Pedro Diaz', status: 'completado' },
      { car: 'AAA-002', status: 'reprogramado' },
      { car: 'AAA-003', driver: 'Maria Lopez', student: 'Ana Sanchez', status: 'programado' },
      { car: 'AAA-004', driver: 'Luis Silva', student: 'Jose Torres', status: 'libre' },
      { car: 'AAA-005', driver: 'Roberto Diaz', student: 'Luis Gomez', status: 'programado' },
      { car: 'AAA-006', status: 'libre' },
      { car: 'AAA-007', driver: 'Miguel Perez', student: 'Clara Mendoza', status: 'programado' },
      { car: 'AAA-008', driver: 'Daniel Ramirez', student: 'Paola Sanchez', status: 'reprogramado' },
    ]
  },
  {
    range: '12:00 - 13:00',
    schedules: [
      { car: 'AAA-001', driver: 'Carlos Gomez', student: 'Pedro Diaz', status: 'programado' },
      { car: 'AAA-002', status: 'reprogramado' },
      { car: 'AAA-003', driver: 'Maria Lopez', student: 'Ana Sanchez', status: 'completado' },
      { car: 'AAA-004', driver: 'Luis Silva', student: 'Jose Torres', status: 'programado' },
      { car: 'AAA-005', driver: 'Roberto Diaz', student: 'Luis Gomez', status: 'programado' },
      { car: 'AAA-006', status: 'libre' },
      { car: 'AAA-007', driver: 'Miguel Perez', student: 'Clara Mendoza', status: 'programado' },
      { car: 'AAA-008', driver: 'Daniel Ramirez', student: 'Paola Sanchez', status: 'reprogramado' },
    ]
  },
  {
    range: '13:00 - 14:00',
    schedules: [
      { car: 'AAA-001', driver: 'Carlos Gomez', student: 'Pedro Diaz', status: 'reprogramado' },
      { car: 'AAA-002', driver: 'Maria Lopez', student: 'Ana Sanchez', status: 'completado' },
      { car: 'AAA-003', status: 'libre' },
      { car: 'AAA-004', driver: 'Luis Silva', student: 'Jose Torres', status: 'programado' },
      { car: 'AAA-005', driver: 'Roberto Diaz', student: 'Luis Gomez', status: 'completado' },
      { car: 'AAA-006', status: 'reprogramado' },
      { car: 'AAA-007', driver: 'Miguel Perez', student: 'Clara Mendoza', status: 'programado' },
      { car: 'AAA-008', status: 'libre' },
    ]
  },
  {
    range: '14:00 - 15:00',
    schedules: [
      { car: 'AAA-001', driver: 'Henry Cerna', student: 'Juan Perez', status: 'programado' },
      { car: 'AAA-002', status: 'completado' },
      { car: 'AAA-003', driver: 'Carlos Gomez', student: 'Pedro Diaz', status: 'reprogramado' },
      { car: 'AAA-004', driver: 'Maria Lopez', student: 'Ana Sanchez', status: 'programado' },
      { car: 'AAA-005', driver: 'Luis Silva', student: 'Jose Torres', status: 'completado' },
      { car: 'AAA-006', status: 'libre' },
      { car: 'AAA-007', driver: 'Roberto Diaz', student: 'Luis Gomez', status: 'programado' },
      { car: 'AAA-008', status: 'reprogramado' },
    ]
  },
  {
    range: '15:00 - 16:00',
    schedules: [
      { car: 'AAA-001', driver: 'Henry Cerna', student: 'Juan Perez', status: 'completado' },
      { car: 'AAA-002', status: 'libre' },
      { car: 'AAA-003', driver: 'Carlos Gomez', student: 'Pedro Diaz', status: 'reprogramado' },
      { car: 'AAA-004', driver: 'Maria Lopez', student: 'Ana Sanchez', status: 'programado' },
      { car: 'AAA-005', driver: 'Luis Silva', student: 'Jose Torres', status: 'libre' },
      { car: 'AAA-006', status: 'reprogramado' },
      { car: 'AAA-007', driver: 'Roberto Diaz', student: 'Luis Gomez', status: 'programado' },
      { car: 'AAA-008', driver: 'Miguel Perez', student: 'Clara Mendoza', status: 'completado' },
    ]
  },
  {
    range: '16:00 - 17:00',
    schedules: [
      { car: 'AAA-001', driver: 'Henry Cerna', student: 'Juan Perez', status: 'programado' },
      { car: 'AAA-002', status: 'libre' },
      { car: 'AAA-003', driver: 'Carlos Gomez', student: 'Pedro Diaz', status: 'reprogramado' },
      { car: 'AAA-004', driver: 'Maria Lopez', student: 'Ana Sanchez', status: 'programado' },
      { car: 'AAA-005', driver: 'Luis Silva', student: 'Jose Torres', status: 'completado' },
      { car: 'AAA-006', status: 'libre' },
      { car: 'AAA-007', driver: 'Roberto Diaz', student: 'Luis Gomez', status: 'programado' },
      { car: 'AAA-008', status: 'reprogramado' },
    ]
  },
  {
    range: '17:00 - 18:00',
    schedules: [
      { car: 'AAA-001', driver: 'Henry Cerna', student: 'Juan Perez', status: 'completado' },
      { car: 'AAA-002', status: 'libre' },
      { car: 'AAA-003', driver: 'Carlos Gomez', student: 'Pedro Diaz', status: 'reprogramado' },
      { car: 'AAA-004', driver: 'Maria Lopez', student: 'Ana Sanchez', status: 'programado' },
      { car: 'AAA-005', driver: 'Luis Silva', student: 'Jose Torres', status: 'libre' },
      { car: 'AAA-006', status: 'reprogramado' },
      { car: 'AAA-007', driver: 'Roberto Diaz', student: 'Luis Gomez', status: 'completado' },
      { car: 'AAA-008', driver: 'Miguel Perez', student: 'Clara Mendoza', status: 'programado' },
    ]
  }
];


const handleReprogram = (car) => {
  // Lógica para reprogramar el elemento
    obtenerClase()
    console.log(`Reprogramar: ${car}`);
  };
    
  const handleAdd = (car) => {
    // Lógica para agregar el elemento
    obtenerClase()
    console.log(`Agregar programación: ${car}`);
  };
    return (
          <>
    {/* Contenedor del calendario con transición de deslizamiento */}
    <div
      className={`overflow-hidden transition-all duration-500 ease-in-out ${
        verCalendario ? 'h-[50%] opacity-100' : 'h-0 opacity-0'
      }`}
      style={{ visibility: verCalendario ? 'visible' : 'hidden' }}
    >
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locale={esLocale}
        height="95%"
        dateClick={handleDateClick}
        events={eventosCalendario}
        eventContent={renderEventContent}
        eventLimit={true}
        customButtons={{
          myCustomButton: {
            text: 'custom!',
            click: function () {
              alert('clicked the custom button!');
            },
          },
        }}
        headerToolbar={{
          left: 'prev,next today myCustomButton',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek',
        }}
        fixedWeekCount={false}
      />
    </div>

    {/* Botón para mostrar/ocultar calendario con efecto de rotación */}
    <div className="flex items-center text-3xl mb-2 bg-gradient-to-r from-blue-900 to-blue-900 rounded-lg p-2 justify-start text-center text-white shadow-lg">
      <button
        onClick={() => setVerCalendario(!verCalendario)}
        className={`mx-5 text-2xl transition-transform transform ${
          verCalendario ? 'rotate-180' : 'rotate-0'
        } hover:text-green-500 hover:scale-110`}
      >
        <i className={`fa ${verCalendario ? 'fa-angle-down' : 'fa-angle-down'}`}></i>
      </button>
      <h2 className="flex-grow text-2xl font-semibold">
        Programación día:{' '}
        {selectedDate
          ? `${selectedDate.split('-')[2]}/${selectedDate.split('-')[1]}/${selectedDate.split('-')[0]}`
          : 'Seleccione un día'}
      </h2>
    </div>

    {/* Contenedor de la programación con scroll y ajuste de altura */}
    <div
      className={`containerScroll overflow-y-auto transition-all duration-500 ease-in-out ${
        verCalendario ? 'max-h-[40%]' : 'max-h-[90%]'
      } mt-2 contenedor-tabla`}
        >
          
<div className="p-4 space-y-4">
  {data.map((item, index) => (
    <div
      key={index}
      className="flex border-b-2 border-gray-200 p-4 rounded-lg transition-all duration-300 ease-in-out hover:shadow-[0_5px_10px_rgba(255,255,255,0.2)]"
    >
      {/* DIV HORAS */}
      <div className="text-2xl font-semibold flex flex-col justify-center text-center border-r-2 border-gray-300 pr-4">
        <span>{item.range.split(' - ')[0]}</span>
        <span>{item.range.split(' - ')[1]}</span>
      </div>
      
      {/* DIV PROGRAMACIONES */}
      <div className="flex flex-wrap gap-6 pl-4 items-center">
        {item.schedules.map((schedule, idx) => (
          <div
            key={idx}
            className={`relative flex flex-col items-start justify-between border px-4 py-2 rounded group 
              transition-all duration-300 ease-in-out 
              ${schedule.status === 'programado' ? 'bg-green-800 border-green-400' 
              : schedule.status === 'reprogramado' ? 'bg-orange-500 border-orange-400' 
              : schedule.status === 'completado' ? 'bg-blue-500 border-blue-400' 
              : 'bg-gray-700 border-gray-400'} 
              hover:shadow-[0_5px_10px_rgba(255,255,255,0.2)]`}
          >
            <div>
              <div className="text-lg font-medium ">
                <i className="fa fa-car mr-2 text-blue-400" />
                <span>{schedule.car}</span>
              </div>
              {schedule.status !== 'libre' && (
                <>
                  <div className="text-md mt-1">
                    <i className="fa fa-user-tie mr-2 text-green-400" />
                    <span>{schedule.driver}</span>
                  </div>
                  <div className="text-md mt-1">
                    <i className="fa fa-graduation-cap mr-2 text-orange-400" />
                    <span>{schedule.student}</span>
                  </div>
                </>
              )}
            </div>

            {/* Ícono para ver detalles en cards con estado 'completado' */}
            {schedule.status === 'completado' && (
              <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <button
                  className="text-blue-500 hover:text-white flex items-center justify-center bg-gray-900 px-5 py-4 rounded-full"
                  onClick={() => alert(`Detalles de completado: ${schedule.car}`)}
                >
                  <i className="fa fa-info-circle text-2xl" />
                </button>
              </div>
            )}

            {/* Ícono para ver detalles en cards con estado 'reprogramado' */}
            {schedule.status === 'reprogramado' && (
              <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <button
                  className="text-orange-500 hover:text-white flex items-center justify-center bg-gray-900 px-5 py-4 rounded-full"
                  onClick={() => alert(`Detalles de reprogramación: ${schedule.car}`)}
                >
                  <i className="fa fa-info-circle text-2xl" />
                </button>
              </div>
            )}

            {/* Ícono de reprogramar/agregar que aparece al hacer hover en el medio del card */}
            {schedule.status === 'programado' && (
              <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <button
                  className="text-blue-500 hover:text-white flex items-center justify-center bg-gray-900 px-5 py-4 rounded-full"
                  onClick={() => handleReprogram(schedule.car)}
                >
                  <i className="fa fa-sync-alt text-2xl" />
                </button>
              </div>
            )}

            {schedule.status === 'libre' && (
              <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <button
                  className="text-green-200 hover:text-white flex items-center justify-center bg-green-700 px-3 py-2 rounded-full"
                  onClick={() => handleAdd(schedule.car)}
                >
                  <i className="fa fa-plus text-2xl" />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  ))}
</div>






                    {/* <table id='tabla-clases' className="tableResponsive ">
                        <thead>
                            <tr>
                                <th className="text-center w-[90px] ">H. Inicio</th>
                                <th className="text-center w-[90px] ">H. Fin</th>
                                <th className="text-center">TIPO CLASE</th>
                                <th className="text-center">ESTUDIANTE</th>
                                <th className="text-center">N° CLASE</th>
                                <th className="text-center">PLACA</th>
                                <th className="text-center">INSTRUCTOR</th>
                                <th className="w-[100px]">ESTADO</th>
                                <th className="w-[100px]"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {   
                                eventsForSelectedDate.length > 0 
                                ? eventsForSelectedDate.map((tc, i) => {
                                    return (
                                        <tr key={tc.codTipoClase}>
                                            <td className="text-center">{tc.FechaHoraInicioProgramado.split('T')[1].split(":")[0]}:{tc.FechaHoraInicioProgramado.split('T')[1].split(":")[1]}</td>
                                            <td className="text-center">{tc.FechaHoraFinProgramado.split('T')[1].split(":")[0]}:{tc.FechaHoraFinProgramado.split('T')[1].split(":")[1]}</td>
                                            <td className="text-center">{tc.NombreTipoClase}</td>
                                            <td className="text-center">{tc.Estudiante}</td>
                                            <td className="text-center">1</td>
                                            <td className="text-center">{tc.Placa}</td>
                                            <td className="text-center">{tc.Instructor}</td>
                                            <td className="text-center">
                                                <span 
                                                    className={`
                                                        ${
                                                            tc.CodEstado == 1? ' bg-yellow-600 ': 
                                                            tc.CodEstado == 2? ' bg-orange-600 ': 
                                                            tc.CodEstado == 3? ' bg-green-600 ': 
                                                            tc.CodEstado == 4? ' bg-red-600 ' : 
                                                            ' bg-gray-500 '
                                                        } 
                                                        text-white rounded px-2 p-1 text-[14px] 
                                                    `}
                                                >
                                                    {tc.NombreEstado}
                                                </span> 
                                            </td>

                                            <td className="text-center w-[100px]" >
                                                <button onClick={() => {console.log("CodTipoClase");}} className="text-white bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded">
                                                    <i className="fas fa-edit"></i>
                                                </button>
                                                <button  onClick={()=> console.log("tc.CodTipoClase")} className="text-white bg-red-500 hover:bg-red-800 text-[14px] px-[5px] py-1 rounded ml-3">
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                                : <tr><td colSpan="9" className="text-center">No hay información que mostrar con los parámetros seleccionados.</td></tr>
                            }
                        </tbody>
                    </table> */}
        </div>
        





        



        <Modal 
          isOpen={isOpenModalProgramacion}
          closeModal={closeModalProgramacion}
          action={() => {guardarEditarClase();listarMatriculas();listarUsuarios()}}
          title={`Programación ${selectedDate.split('-')[2]}/${selectedDate.split('-')[1]}/${selectedDate.split('-')[0]}`}
          width={'50%'}
        >
        <div className="lg:flex-col lg:max-h-[500px] lg:overflow-y-auto text-l flex justify-center items-start containerScroll bg-gray-900 text-white p-4 rounded-lg">

          <div className="w-full px-4 lg:flex-col lg:flex">
            <div className="flex flex-col justify-between w-full form-content">

                
                  <div className="lg:flex-col lg:w-full flex space-x-4 mb-4">
                    <div className="w-2/3 lg:flex-col lg:w-full">
                      <CustomAutocomplete 
                        label="Estudiante"
                        value={selectedStudent}
                        onChange={handleStudentChange}
                        options={usuariosEstudiantes}
                        labelKey="Usuario"
                        valueKey="CodUsuario"
                        secondLabelKey="DNI"
                      />
                    </div>
                    <div className="w-1/3 lg:flex-col lg:w-full flex items-center justify-center">
                  {selectedStudent && (
                    <Avatar alt={selectedStudent.Nombres} src={selectedStudent.Foto} sx={{ width: 56, height: 56 }} />
                  )}
                    </div>
                  </div>

              {/* Cantidad de Horas y Monto Paquete en la misma fila */}
              <div className="flex lg:space-x-0 space-x-4 mb-4 lg:w-full lg:flex-col">
                <div className="w-1/2 lg:w-full">
                  <CustomTextField
                    label="Número de clase (Hora)"
                    defaultValue={numeroHora}
                    value={numeroHora}
                      // onChange={(e) => handleDecimalInputChange("CantidadHoras", e.target.value)}
                  />
                </div>
              {/* </div>
              <div className="flex space-x-4 mb-4"> */}
                <div className="w-1/2 lg:w-full mt-5">
                  <CustomTextField
                    label="Horas contratadas"
                    value={matriculaFiltrada?.CantidadHoras}
                    // onChange={(e) => handleDecimalInputChange("CantidadHoras", e.target.value)}
                  />
                </div>
              </div>

                <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                  

                 
                  
                                    {/* <label>Estudiante: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select 
                                            className={`${classNameInput} `} 
                                            defaultValue={matricula.Estudiante} 
                                            onChange={(e) => {editarValorClase("Estudiante", e.target.value);setEstudianteSeleccionado(e.target.value)}} 
                                        >
                                            <option className='' value={0}>-- Seleccione --</option>
                                            {
                                                usuariosEstudiantes?.map(u => {
                                                    return <option key={u.CodUsuario} value={u.Usuario}>{u.Usuario} ({u.Nombres})</option>
                                                })
                                            }
                                        </select>
                                    </div> */}
                                </div>

                                {/* <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Número de hora: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input 
                                            defaultValue={numeroHora}
                                            value={numeroHora}
                                            className={classNameInput}
                                            disabled
                                        />
                                        <label>de</label>

                                        <input 
                                            defaultValue={matriculaFiltrada?.CantidadHoras}
                                            value={matriculaFiltrada?.CantidadHoras}
                                            className={classNameInput}
                                            disabled
                                        />
                                    </div>
                                </div> */}
                              <div className="flex space-x-4 lg:space-x-0 mb-4 lg:flex-col">

                                <div className="w-1/2 lg:w-full ">
                                  <CustomTextField
                                    label="Pago Pendiente"
                                    value={(matriculaFiltrada?.Monto - matriculaFiltrada?.MontoPagado) || 0}
                                            
                                    // onChange={(e) => handleDecimalInputChange("Monto", e.target.value)}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <span style={{ color: '#ffffff' }}>S/.</span>
                                        </InputAdornment>
                                      ),
                                      className: "bg-gray-700 text-white",
                                      style: { color: '#ffffff' }
                                    }}
                                  />
                                </div>
                                <div className="w-1/2 lg:w-full mt-5">
                                  <CustomSelect
                                    label="Transmisión"
                                    defaultValue={tipoClaseFiltrado?.CodTipoVehiculo}
                                    value={tipoClaseFiltrado?.CodTipoVehiculo}
                                    // onChange={(e) => editarValorUsuario('CodTipoDocumento', e.target.value)}
                                    options={tiposVehiculos.map(tv => ({
                                        value: tv.CodTipoVehiculo,
                                        label: tv.NombreTipoVehiculo
                                    }))}
                                  />
                                </div>
                              </div>

                                {/* <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Pago Pendiente: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input 
                                            defaultValue={(matriculaFiltrada?.Monto - matriculaFiltrada?.MontoPagado) || 0}
                                            value={(matriculaFiltrada?.Monto - matriculaFiltrada?.MontoPagado) || 0}
                                            className={classNameInput}
                                            disabled
                                        />
                                    </div>
                                </div> */}

                                {/* <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Tipo de vehículo: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                    <select 
                                        className={`${classNameInput} `} 
                                        defaultValue={tipoClaseFiltrado?.CodTipoVehiculo} 
                                        value={tipoClaseFiltrado?.CodTipoVehiculo} 
                                        onChange={(e) => {setTipoVehiculoSeleccionado(e.target.value)}} 
                                        disabled
                                    >
                                        {!tipoVehiculoSeleccionado && <option className='' value={100}> Seleccione </option>}
                                        <option className='' value={0}> Cualquiera </option>
                                        {
                                            tiposVehiculos?.map(r => {
                                                return <option key={r.CodTipoVehiculo} value={r.CodTipoVehiculo}>{r.NombreTipoVehiculo}</option>
                                            })
                                        }
                                    </select>
                                    </div>
                                </div> */}
                
                                {/* <div className="lg:flex-col lg:w-full">
                                  <CustomAutocomplete
                                    label="Vehículo"
                                    value={matricula.CodVehiculo}
                                    onChange={(e) => {editarValorClase("CodVehiculo", e.target.value)}}
                                    options={vehiculosFiltrados}
                                    labelKey="Placa"
                                    valueKey="CodVehiculo"
                                    secondLabelKey="Marca"
                                    disabled={noHayMatricula}
                                  />
                                </div> */}
                
                                <div className="lg:flex-col lg:w-full flex space-x-4 mb-4">
                                  <div className="w-2/3 lg:flex-col lg:w-full">
                                    <CustomAutocomplete 
                                      label="Vehículo"
                                      value={matricula.CodVehiculo}
                                      onChange={(e) => {editarValorClase("CodVehiculo", e.target.value)}}
                                      options={vehiculosFiltrados}
                                      labelKey="Placa"
                                      valueKey="CodVehiculo"
                                      secondLabelKey="Marca"
                                      disabled={noHayMatricula}

                                    />
                                  </div>
                                  {/* <div className="w-1/3 lg:flex-col lg:w-full flex items-center justify-center">
                                    {selectedStudent && (
                                      <Avatar alt={selectedStudent.Nombres} src={selectedStudent.Foto} sx={{ width: 56, height: 56 }} />
                                    )}
                                  </div> */}
                                </div>


                                {/* <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Vehículo: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                    <select 
                                        className={`${classNameInput} `} 
                                        defaultValue={matricula.CodVehiculo} 
                                        onChange={(e) => {editarValorClase("CodVehiculo", e.target.value)}} 
                                        disabled={noHayMatricula}
                                    >
                                        <option value={0}>-- Seleccione --</option>
                                        {
                                            vehiculosFiltrados.map((v,i)=> {
                                                return (
                                                    <option key= {v.CodVehiculo} value={v.CodVehiculo}>[{v.Placa}] {v.Marca} {v.Modelo}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    </div>
                                </div> */}
                
                                <div className="lg:flex-col lg:w-full flex space-x-4 mb-4">
                                  <div className="w-2/3 lg:flex-col lg:w-full">
                                    <CustomAutocomplete 
                                      label="Instructor"
                                      value={matricula.Instructor}
                                      onChange={(e) => {editarValorClase("Instructor", e.target.value)}}
                                      options={usuariosInstructores}
                                      labelKey="Usuario"
                                      valueKey="CodUsuario"
                                      secondLabelKey="Nombres"
                                      disabled={noHayMatricula}

                                    />
                                  </div>
                                  {/* <div className="w-1/3 lg:flex-col lg:w-full flex items-center justify-center">
                                    {selectedStudent && (
                                      <Avatar alt={selectedStudent.Nombres} src={selectedStudent.Foto} sx={{ width: 56, height: 56 }} />
                                    )}
                                  </div> */}
                                </div>

                                {/* <div className="lg:flex-col lg:w-full">
                                  <CustomAutocomplete
                                    label="Instructor"
                                    value={matricula.Instructor}
                                    onChange={(e) => {editarValorClase("Instructor", e.target.value)}} 
                                    options={usuariosInstructores}
                                    labelKey="Usuario"
                                    valueKey="CodUsuario"
                                    secondLabelKey="Nombres"
                                    disabled={noHayMatricula}
                                  />
                                </div> */}
                
                                {/* <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Instructor: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select 
                                            className={`${classNameInput} `} 
                                            defaultValue={matricula.Instructor} 
                                            onChange={(e) => {editarValorClase("Instructor", e.target.value)}} 
                                            disabled={noHayMatricula}
                                        >
                                            <option className='' value={0}>-- Seleccione --</option>
                                            {
                                                usuariosInstructores?.map(u => {
                                                    return <option key={u.CodUsuario} value={u.Usuario}>{u.Usuario} ({u.Nombres})</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div> */}

                            

                                {/* <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Hora Inicio: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        
                                        <input 
                                            onChange={handleHoraInicioChange}  
                                            type='time' 
                                            className={`${classNameInput} text-center center justify-center `}
                                            disabled={noHayMatricula}
                                        />

                                       
                                    </div>
                                </div> */}
                                {/* <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Hora Final: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                    
                                    <input 
                                        onChange={handleHoraFinChange}  
                                        type='time' 
                                        className={`${classNameInput} text-center center justify-center `}
                                        disabled={noHayMatricula}
                                    />

                                       
                                    </div>
                                </div> */}
                                {/* <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Horas: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input 
                                            className={classNameInput}
                                            disabled    
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                </div>
                </Modal>

        </>
    );
};
