import React from 'react';
import { TextField } from '@mui/material';

export const CustomTextField = ({ label, value, onChange, type = 'text', onKeyPress, ...props }) => {
    return (
        <TextField
            fullWidth
            variant="filled"
            label={label}
            value={value}
            onChange={onChange}
            type={type}
            onKeyPress={onKeyPress}
            className="bg-gray-700 text-white rounded-t"
            InputLabelProps={{ style: { color: '#DEDEDE' } }}
            InputProps={{ style: { color: 'white' } }}
            {...props}
        />
    );
};
