import React, { useEffect, useState, useRef, useContext } from 'react';
import { UserContext } from '../../../context/provider/UserProvider';
import { useTipoClases } from '../../../hooks/useTipoClases';
import { useMatricula } from '../../../hooks/useMatricula';
import { useUsuarios } from '../../../hooks/useUsuarios';
import { useTiposVehiculos } from '../../../hooks/useTiposVehiculos';
import LinesChart from '../../components/LinesChart';

export const ReporteClases = () => {
    
    const {
        usuarios,
    } = useUsuarios()

    const usuariosEstudiantes = usuarios.filter(u => u.CodRol == 4);


    const {
        tiposVehiculos
    } = useTiposVehiculos()

    const {
        matricula,
        listarMatriculas,
        guardarEditarMatricula,
        matriculas,
        editarValorMatricula,
        obtenerMatricula,
        spinner,
        isOpen,
        closeModal,
        openModal
    } = useMatricula()

    const {
        tipoClase,
        listarTipoClases,
        guardarEditarTipoClase,
        tipoClases,
        editarValorTipoClase,
        obtenerTipoClase,
    } = useTipoClases()

    const [ fechaPago, setFechapago ] = useState( new Date())
    const [ fechaMatricula, setFechaMatricula ] = useState( new Date())
    const { stateUser }  = useContext(UserContext)



    useEffect(()=>{
        editarValorMatricula("FechaPago", fechaPago)
        editarValorMatricula("FechaMatricula", fechaMatricula)
    },[fechaMatricula, fechaPago])

    return (
        <>
            <h1 className=' text-2xl underline mt-5 ml-5'>Reporte de Instructores</h1>
            <div className="containerScroll  h-max-[40%] mt-2  relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
            <table id='tabla-personas' className="tableResponsive ">
                    <thead>
                        <tr>
                            {/* <th className="w-[40px] text-center">N</th> */}
                            <th className='w-2'></th>
                            <th className='text-center'>NOMBRES</th>
                            <th>HORAS</th>
                            <th>CALIFICACION</th>
                            <th>VEHICULO</th>
                            <th>ESTUDIANTES</th>
                            {/* <th>USUARIO</th> */}
                            {/* <th></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {  
                            usuarios.length > 0
                            ? usuarios.map((u,i)=> {
                                return (
                                <tr key={u.CodUsuario} >
                                    {/* <td>{ i + 1 }</td> */}
                                    <td className='w-[65px] '>
                                        <img className='rounded-full' src={u.Foto}/>
                                    </td>
                                    <td className="text-center">{u.Nombres}</td>
                                    <td className="text-center">5</td>
                                    <td className="text-center">
                                        <i className='fa fa-star text-yellow-400'></i>    
                                        <i className='fa fa-star text-yellow-400'></i>    
                                        <i className='fa fa-star text-yellow-400'></i>    
                                        <i className='fa fa-star text-yellow-400'></i>    
                                        <i className='fa fa-star'></i>    
                                    </td>
                                    <td className="text-center">AJO-497</td>
                                    <td className="text-center">3</td>
                                    {/* <td className="text-center">{u.Usuario}</td> */}
                                </tr>
                                )
                            })
                            : <tr><td colSpan={'9'} className='text-center'>No hay información para mostrar por el momento</td></tr>
                        }
                        
                    </tbody>
                </table>
            </div>

            {/* <div className='flex'>
                <div className={`m-2 h-[90px] text-white rounded-lg bg-slate-800 shadow-md w-full p-2 relative flex flex-col items-right`}>
                    <div className=" mt-2 text-right">
                        <p className={`text-3xl font-bold`}>{matriculas?.length}</p>
                        <p className="text-xl text-gray-400 border-t border-gray-500">
                            Total Matriculas
                        </p>
                    </div>
                </div>
                <div className={`m-2 h-[90px] text-white rounded-lg bg-slate-800 shadow-md w-full p-2 relative flex flex-col items-right`}>
                    <div className=" mt-2 text-right">
                        <p className={`text-3xl font-bold`}> S/. {0}</p>
                        <p className="text-xl text-gray-400 border-t border-gray-500">
                            Monto Total
                        </p>
                    </div>
                </div>
                <div className={`m-2 h-[90px] text-white rounded-lg bg-slate-800 shadow-md w-full p-2 relative flex flex-col items-right`}>
                    <div className=" mt-2 text-right">
                        <p className={`text-3xl font-bold`}> {0}</p>
                        <p className="text-xl text-gray-400 border-t border-gray-500">
                            Pagos Completos
                        </p>
                    </div>
                </div>
                <div className={`m-2 h-[90px] text-white rounded-lg bg-slate-800 shadow-md w-full p-2 relative flex flex-col items-right`}>
                    <div className=" mt-2 text-right">
                        <p className={`text-3xl font-bold`}> {'usuario'}</p>
                        <p className="text-xl text-gray-400 border-t border-gray-500">
                            Mayor Matriculas
                        </p>
                    </div>
                </div>
            </div> */}
                

                

            {/* <div className='h-[30%] w-full flex'>
                <LinesChart></LinesChart>
            </div> */}

            
        </>
    )


}