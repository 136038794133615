import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal } from '../../components/modal/Modal';
import { soloCelular, soloDNI } from '../../../utils/utils';
import { UserContext } from '../../../context/provider/UserProvider';
import { Tooltip } from '../../components/utils/Tooltip';
import { useDocumentos } from '../../../hooks/useDocumentos';
import { Cabecera } from '../../components/utils/Cabecera';

export const Documentos = () => {
    const [ fechaEmision, setFechaEmision ] = useState(new Date())
    const [ fechaVencimiento,setFechaVencimiento ] = useState(new Date())
    const classNameInput = "text-gray-800 h-[30px] rounded px-4 w-full  text-l border focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const {
        documentos,
        listarDocumentos,
        guardarEditarDocumento,
        documento,
        editarValorDocumento,
        obtenerDocumento,
        spinner,
        isOpen,
        closeModal,
        openModal
  } = useDocumentos()
  
    const [filteredDocumentos, setFilteredDocumentos] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    useEffect(() => {
        if (searchTerm === "") {
            setFilteredDocumentos(documentos);
        } else {

            const filtered = documentos.filter(tipoClase =>
                [ 'Descripcion', 'Nombre', 'NombreTipoVehiculo','Monto']
                .some(key => 
                    tipoClase[key] && typeof tipoClase[key] === 'string' && tipoClase[key].toLowerCase().includes(searchTerm)
                )                
            );
            setFilteredDocumentos(filtered);
        }
    }, [searchTerm, documentos]);
    
    const filtros = [
        { nombre: 'Nombre', label: 'Nombre' },
    ];

  

  
  
    return (
        <>
        <Cabecera
          titulo="Documentos"
          onNuevo={() => obtenerDocumento()}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          data={documentos}
          updateFilteredData={setFilteredDocumentos}
          searchFields={[ 'Descripcion', 'Nombre', 'NombreTipoVehiculo','Monto']}
          placeholder="Buscar en tipos de clases..."
          nuevoTexto="Nuevo" 
          filtros={filtros}
      />      


          <div className="">
                    <ul className="cards">
                        {
                        documentos.length > 0 ? documentos.map((documento, i)=> {
                            return (
                                <li className='cursor-pointer'>
                                    <div className="details ">

                                        <span ><i className='fa fa-address-card mr-2'/>{documento.Nombre}</span>
                                        <span ><i className='fa fa-user mr-2'/>{documento.FechaEmision}</span>
                                        <span ><i className='fa fa-clock mr-2'/>{documento.FechaVencimiento}</span>
                                        <span >
                                            <button 
                                                onClick={() => {obtenerDocumento(documento.CodDocumento);}} 
                                                className="text-white bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded"
                                            >
                                                <i className="fas fa-edit"/> Editar
                                            </button>
                                            <button  
                                                onClick={() => {obtenerDocumento(documento.CodDocumento);}} 
                                                className="text-white bg-red-500 hover:bg-red-800 text-[14px] px-[5px] py-1 rounded ml-3"
                                            >
                                                <i className="fas fa-trash"/>Eliminar
                                            </button>
                                        </span>
                                    </div>
                                </li>
                            )
                            })
                            : <div className='text-center'>No hay información para mostrar por el momento</div>
                        }
                    </ul>
                </div>


            <Modal 
                isOpen={isOpen} closeModal={closeModal}
                action={() => guardarEditarDocumento()}
                title={`${documento.CodDocumento? 'Editar':'Nuevo'}`}
            >
                <div className="flex  items-center flex-wrap justify-center">
                        <div className="w-[500px] px-4">
                            <div className="flex flex-col justify-between w-full form-content">                        
                                
                                <div className='lg:flex-col lg:w-full'>
                                    <label>Nombre: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input  
                                            className={`${classNameInput} caret-black`} 
                                            defaultValue={documento.Nombre} 
                                            onChange={(e) => editarValorDocumento("Nombre", e.target.value)} 
                                        />
                                    </div>
                                </div>
                                
                                <div className='lg:flex-col lg:w-full'>
                                    <label>Fecha de Emision: </label>
S                                </div>
                                
                                <div className='lg:flex-col lg:w-full'>
                                    <label>Fecha de Vencimiento: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        
                                       
                                    </div>
                                </div>

                                <div className='lg:flex-col lg:w-full'>
                                    <label>Destino: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input  
                                            className={`${classNameInput} caret-black`} 
                                            defaultValue={documento.Destino} 
                                            onChange={(e) => editarValorDocumento("Nombre", e.target.value)} 
                                        />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    
                </div>
            </Modal>

            {/* <Modal 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarPersona(persona.codPersona)}
                title= "Eliminar Persona"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                    <div className="flex gap-8 items-center flex-wrap justify-center">
                        <div className="w-[400px] px-4">
                            <label className="text-center">¿Está seguro de eliminar la Persona? </label>
                        </div>
                    </div>
                    
                
            </Modal>   

 */}
        </>
    )


}