import React from 'react';
import { Autocomplete, TextField, FormControl } from '@mui/material';

export const CustomAutocomplete = ({ 
    label, 
    value, 
    onChange, 
    options = [], 
    labelKey = 'label', 
    valueKey = 'value', 
    secondLabelKey = '', // Añadimos una segunda clave para la concatenación
    disabled = false, 
    ...props 
}) => {
    return (
        <FormControl fullWidth variant="filled" sx={{ bgcolor: '#374151', borderRadius: '4px' }} disabled={disabled}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => onChange(event, newValue)}
                options={options}
                getOptionLabel={(option) => 
                    `${option[labelKey]}${secondLabelKey ? ` (${option[secondLabelKey]})` : ''}` || ''} // Concatenamos los campos
                isOptionEqualToValue={(option, value) => option[valueKey] === value[valueKey]}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="filled"
                        disabled={disabled} 
                        sx={{
                            bgcolor: disabled ? '#b0b0b0' : '#374151', 
                            borderRadius: '4px',
                            '& .MuiInputBase-input': {
                                color: '#ffffff', 
                            },
                            '& .MuiInputLabel-root': {
                                color: disabled ? '#d3d3d3' : '#ffffff',
                            },
                            '& .MuiSvgIcon-root': {
                                color: disabled ? '#d3d3d3' : '#ffffff',
                            },
                        }}
                    />
                )}
                sx={{
                    '& .MuiAutocomplete-popupIndicator': {
                        color: '#ffffff',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                        color: '#ffffff',
                    },
                    '& .MuiAutocomplete-option': {
                        bgcolor: '#374151',
                        color: '#ffffff',
                        '&:hover': {
                            bgcolor: '#ffffff',
                            color: '#000000',
                        },
                    },
                    '& .MuiAutocomplete-listbox': {
                        bgcolor: '#374151',
                        color: '#ffffff',
                    },
                    '& .MuiAutocomplete-paper': {
                        bgcolor: '#374151',
                    },
                    '& .MuiAutocomplete-noOptions': {
                        color: '#ffffff',
                    },
                    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
                        color: '#ffffff',
                        '& .MuiAutocomplete-input': {
                            color: '#ffffff',
                        },
                    },
                }}
                disabled={disabled}
                {...props}
            />
        </FormControl>
    );
};
