import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, CircularProgress, Paper, Slide } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSpinner } from '../../../hooks/useSpinner';
import Draggable from 'react-draggable';

const PaperComponent = (props) => {
  return (
    <Draggable handle=".draggable-handle" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} style={{ backgroundColor: '#111827', borderRadius: '10px', boxShadow: 'none' }} />
    </Draggable>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Modal = ({
  isOpen,
  closeModal,
  action,
  width,
  children,
  title,
  cerrarAlGuardar = false,
  textButtons = { confirm: 'Guardar', denied: 'Cancelar' },
  validButton = { confirm: true, denied: true },
  clasesModal
}) => {
  const [spinner, mostrarSpinner, ocultarSpinner] = useSpinner();

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition} // Añadido para el efecto de entrada
      PaperProps={{
        sx: {
          width: width ? width : 'auto',
          maxWidth: '90%',
          borderRadius: '10px',
          boxShadow: 'none',
          // Consulta de medios para pantallas móviles
          '@media (max-width: 600px)': {
            width: '100%', // Ancho al 100% en móviles
            maxWidth: '100%', // Ancho máximo también al 100% en móviles
            height: '80%', // Para que también ocupe el 100% de la altura
            borderRadius: '0px', // Sin bordes redondeados en móviles
          },
        },
        className: clasesModal,
      }}
    >
      <DialogTitle className="draggable-handle flex justify-between items-center cursor-move bg-gray-800 text-white">
        <span>{title}</span>
        <IconButton onClick={closeModal}>
          <CloseIcon className="text-white" />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          backgroundColor: '#111827',
          color: 'white',
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        {children} 
      </DialogContent>

      <DialogActions className="p-4 bg-gray-800">
        {validButton.confirm && (
          <button
            className="bg-green-600 text-white font-bold py-2 px-4 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 disabled:bg-green-400"
            onClick={async () => {
              mostrarSpinner();
              await action();
              ocultarSpinner();
              cerrarAlGuardar && closeModal();
            }}
            disabled={spinner}
          >
            {spinner ? <CircularProgress size={24} className="text-white" /> : textButtons.confirm}
          </button>
        )}
        {validButton.denied && (
          <button
            className="bg-red-600 text-white font-bold py-2 px-4 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
            onClick={closeModal}
          >
            {textButtons.denied}
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};
