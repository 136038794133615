import { useState, useEffect, useContext } from "react";
import { notify } from "../utils/utils";
import { AuthFetch } from "../services/api";
import { useModal } from "./useModal";
import { useSpinner } from "./useSpinner";
import { UserContext } from "../context/provider/UserProvider";

const urlDocumentos = process.env.REACT_APP_PROMETHEUS_API + "/documento";

export const useDocumentos = () => {
    const { stateUser , signOut } = useContext(UserContext);
    const documentoDefault = {
        CodDocumento: 0,
        Nombre: "",
        FechaEmision: "",
        FechaVencimiento: "",
    }
    const [ documentos, setDocumentos ] = useState([]);
    const [ documento, setDocumento ] = useState(documentoDefault);
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner();
    const [ isOpen, openModal, closeModal] = useModal();
    
    useEffect(() => {
        listarDocumentos();
    }, []);

    const listarDocumentos = async () => {
        console.log("INGRESO A LA FUNCION LISTAR DOCUMENTOS ")
        const response = await AuthFetch({
            url: urlDocumentos + '?'+ new URLSearchParams({
                cod_empresa : stateUser.codEmpresa
            })
        });
        if (response.isValid) {
            console.log("RESPONSE DOCUMENTOS -> ", response.content)
            setDocumentos(response.content);
        } else {
            notify(response.content, 'error');
        }
    }


    const obtenerDocumento= async (codDocumento) => {
        if (codDocumento) {
            const response = await AuthFetch({
                url: urlDocumentos + '/obtenerDocumento?'+ new URLSearchParams({
                    cod_documento : codDocumento,
                    cod_empresa : stateUser.codEmpresa,
                })
            });
            console.log("RESPONSE OBTENER DOCUMENTO -> ", response)
            if (response.isValid) {
                setDocumento(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setDocumento(documentoDefault);
        }
        openModal();
    }
    
    
    
    const guardarEditarDocumento = async () => {
        const esGuardar = documento.CodDocumento == 0;
        const response = await AuthFetch({
            url: urlDocumentos ,
            method: esGuardar ? 'POST' : 'PUT',
            body : JSON.stringify({
                ...(!esGuardar && { CodDocumento: documento.CodDocumento }),
                Nombre: documento.Nombre,
                FechaEmision: documento.FechaEmision,
                FechaVencimiento: documento.FechaVencimiento,
                codEmpresa: stateUser.codEmpresa
            })
        });
        notify(response.content, response.isValid ? 'success' : 'error');
        if (response.isValid) {
            setDocumento(documentoDefault);
            await listarDocumentos();
            closeModal()
        }
    }

    const editarValorDocumento = (key,value) =>{
        console.log("EDITAR VALOR DOCUMENTO -> ", key,value)
        setDocumento(documento => {
            return {
                ...documento,
                [key]: value
            }
        });
    }     
    return {
        documentos,
        listarDocumentos,
        guardarEditarDocumento,
        documento,
        editarValorDocumento,
        obtenerDocumento,
        spinner,
        isOpen,
        closeModal,
        openModal
    };
}