import { useState, useEffect, useContext } from "react";
import { notify } from "../utils/utils";
import { AuthFetch, Fetch } from "../services/api";
import { useModal } from "./useModal";
import { useSpinner } from "./useSpinner";
import { UserContext } from "../context/provider/UserProvider";

const urlUsuarios = process.env.REACT_APP_PROMETHEUS_API + "/usuarios";

export const useUsuarios = () => {
  const { stateUser , signOut } = useContext(UserContext);
  const usuarioDefault = {
    CodUsuario: 0,
    Usuario: '',
    Clave: '',
    DNI: '',
    Nombres: '',
    ApellidoPaterno: '',
    ApellidoMaterno: '',
    FechaNacimiento: new Date(),
    Direccion: '',
    Celular: '',
    Email: '',
    Foto: 'https://cdn-icons-png.flaticon.com/256/1535/1535791.png',
    CodEmpresa: 0,
    Empresa: '',
    CodRol: 0,
    CodTipoDocumento: 1,
    BDEscuela: ''
  }
  const [ usuarios, setUsuarios ] = useState([]);
  const [ usuario, setUsuario ] = useState(usuarioDefault);
  const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner();
  const [ isOpen, openModal, closeModal] = useModal();
  const [ fotoFile, setFotoFile ] = useState(null); 
  const [ fotoUrlUsuario, setFotoUrlUsuario ] = useState();
  const [ cargandoBusquedaDNI, setCargandoBusquedaDNI ] = useState()

  useEffect(() => {
    listarUsuarios();
  }, []);

  const listarUsuarios = async () => {
    const response = await AuthFetch({
      url: urlUsuarios + '?'+ new URLSearchParams({
          database    : stateUser.BDEscuela 
      })
    });
    if (response.isValid) {
      setUsuarios(response.content);
    } else {
      notify(response.content, 'error');
    }
  }

  const guardarUsuarioFoto = async () => {
    if (fotoFile != null) {
      if (fotoFile.size > 1024 * 1024) {
        notify("Optimizando Foto, vuelva a intentarlo","info")
        try {
            const resizedImage = await resizeImage(fotoFile);
            setFotoFile(resizedImage);
        } catch (error) {
            notify("Error al redimensionar la imagen", "error");
            return;
        }
      }
      const fotoData = new FormData();
      fotoData.append('archivo', fotoFile);
      const response = await Fetch({
        url: process.env.REACT_APP_PROMETHEUS_MINIO + '/api/Archivo/subirArchivo?' + new URLSearchParams({
          NombreCarpeta: "usuarios",
          NombreArchivo: `${usuario.DNI}`,
        }),
        headers: {
          contentType: 'image/*'
        },
        method: 'POST',
        body: fotoData
      });

      if (response.isValid) {
        setFotoFile(null)
        const timestamp = new Date().getTime();
        usuario.Foto = `${process.env.REACT_APP_PROMETHEUS_MINIO}/api/Archivo/verImagen?NombreCarpeta=usuarios&NombreImagen=${usuario.DNI}&t=${timestamp}`;
        guardarEditarUsuario()
      } else {
        notify(response.exceptions[0].description, 'error');
      }
    } else {
      guardarEditarUsuario()
    }
  }

  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxWidth = 600;
          const maxHeight = 600;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              if (blob) {
                if (blob.size > 1024 * 1024) {
                  canvas.toBlob(
                    (compressedBlob) => {
                      if (compressedBlob) {
                        resolve(new File([compressedBlob], file.name, { type: file.type }));
                      } else {
                        reject(new Error("Error al crear el blob de la imagen comprimida"));
                      }
                    },
                    "image/jpeg",
                    0.5
                  );
                } else {
                  resolve(new File([blob], file.name, { type: file.type }));
                }
              } else {
                reject(new Error("Error al crear el blob de la imagen"));
              }
            },
            "image/jpeg",
            0.5
          );
        };
        img.onerror = (error) => reject(error);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const obtenerUsuario = async (uid) => {
    if (uid) {
      const response = await AuthFetch({
        url: urlUsuarios + '/usuario?' + new URLSearchParams({
          usuario: uid,
        })
      });
      if (response.isValid) {
        setUsuario(response.content);
        setFotoUrlUsuario(response.content.Foto)
      } else {
        notify(response.content, 'error');
      }
    } else {
      setUsuario(usuarioDefault);
      setFotoUrlUsuario(null)
    }
    openModal();
  }
    
  const obtenerDatosIdentidad = async (dni) =>{
    setCargandoBusquedaDNI(true)
    if (dni) {
      const response = await AuthFetch({
        url: `https://identidadwebapi.abexacloud.com/api/Identidad/obtenerDni?dni=${dni}`
      });
      if (response.isValid) {
        const fechaNac = response.content.fechaNacimiento; // "14/01/1987"
        const parts = fechaNac?.split('/');
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        setUsuario({
          ...usuario,
          ApellidoPaterno: response.content.apellidoPaterno,
          ApellidoMaterno: response.content.apellidoMaterno,
          Nombres: response.content.nombres,
          FechaNacimiento: formattedDate,
          Direccion: response.content.direccion,
        });
      } else {
        notify(response.exceptions[0].description, 'error');
      }
    } else {
      setUsuario(usuarioDefault);
    }   
    setCargandoBusquedaDNI(false)
  }

  const guardarEditarUsuario = async () => {
    if(!usuario.CodRol){
      notify("Debe seleccionar un Rol","error")
      return
    }
    if(!usuario.Usuario){
      notify("Debe ingresar un Usuario","error")
      return
    }
    const esGuardar = usuario.CodUsuario == 0? true : false;
    const response = await AuthFetch({
      url: urlUsuarios,
      method: esGuardar ? 'POST' : 'PUT',
      body: JSON.stringify({
        CodUsuario: usuario.CodUsuario,
        Usuario: usuario.Usuario,
        Clave: usuario.Clave,
        DNI: usuario.DNI,
        Nombres: usuario.Nombres,
        ApellidoPaterno: usuario.ApellidoPaterno,
        ApellidoMaterno: usuario.ApellidoMaterno,
        FechaNacimiento: usuario?.FechaNacimiento,
        Direccion: usuario.Direccion,
        Celular: usuario.Celular,
        Email: usuario.Email,
        Foto: usuario.Foto,
        CodEmpresa:stateUser.codEmpresa,
        CodRol:usuario.CodRol,
        Empresa: stateUser.codEmpresa + '',
        BDEscuela: stateUser.BDEscuela
      })
    });

    notify(response.content, response.isValid ? 'success' : 'error');
    if (response.isValid) {
      setUsuario(usuarioDefault);
      setFotoUrlUsuario("");
      closeModal()
      await listarUsuarios();
    }
  }

  const editarValorUsuario = (key,value) =>{
    setUsuario(usuario => {
      return {
        ...usuario,
        [key]: value
      }
    });
  }     
  return {
    usuarios,
    listarUsuarios,
    guardarEditarUsuario,
    usuario,
    editarValorUsuario,
    obtenerUsuario,
    spinner,
    isOpen,
    closeModal,
    openModal,
    obtenerDatosIdentidad,
    guardarUsuarioFoto,
    fotoUrlUsuario,
    setFotoFile,
    setFotoUrlUsuario,
    cargandoBusquedaDNI
  };
}