import React, { useState, useEffect, useRef, useContext } from 'react';
import '../../assets/css/views/login.css'
import { useHistory } from "react-router-dom";
import { UserContext } from '../../context/provider/UserProvider'
// import { SocketContext } from '../../context/provider/SocketProvider'
import { notify } from '../../utils/utils'
import imagenFondo from '../../assets/images/bannerPrincipal.jpg'
// import img1 from '../../assets/images/FACT_ELECTRONICA.png';
// import img2 from '../../assets/images/icoXbest646a.png';
// import img3 from '../../assets/images/icoXbest646b.png';
// import {Slideshow, Slide} from '../components/utils/SliderShow';
// import logo from '../../assets/images/PlaminLogo-Web.png'
import logo from '../../assets/images/em.fw.png'
// import { Loading } from '../components/Loading';
import { Loading } from '../components/Loading';

export const iniciarSesion = async (user, password, signIn) => {
    
    const response = await fetch(process.env.REACT_APP_PROMETHEUS_API + '/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            Usuario: user,
            Clave: password
        })
    }).then(res => res.json());
    console.log("RESPONSE-> ", response)
    if (response.isValid) {
        // const timeExpire = new Date(new Date().getTime() + response.content.tiempoExpiraMiliSegundos * 1000);

        signIn(
            response.content,
            // tokenExpire: timeExpire
        );
        return true
    }
    return false
    // return true
}



const useLogin = () => {
    const inputUsuario = useRef();
    const history = useHistory();
    const { stateUser, signIn } = useContext(UserContext);
    // const { mqttConnect } = useContext(SocketContext);
    const [ user, setUser ] = useState();
    const [ password, setPassword ] = useState(); 
    const [ mostrarLoading, setMostrarLoading ] = useState(false)

    // useEffect(() => {
    //     const isLogged = stateUser.token != ''
    //     isLogged && history.push('/dashboard')
        
    //     inputUsuario.current.focus()
    // }, [])
    
    // useEffect(()=>{

    // },[mostrarLoading])

    const ingresar = async (e) => {
        setMostrarLoading(true)
        e.preventDefault();
        const ingreso = await iniciarSesion(user, password, signIn);
        if (ingreso) {
            // mqttConnect()
            history.push('/dashboard')      
            

            // AREA DE NOTIFICACIONES PUSH
            if(Notification.permission === "default"){
                await Notification.requestPermission()
            }

            if(Notification.permission === "blocked"){
                alert("Notificaciones bloqueadas")
            }

            if(Notification.permission !== "granted"){
                return;
            }
            ///FIN AREA DE NOTICIONES 
        } else {
            notify('Usuario o contraseña incorrectos', 'error')
        }
        setMostrarLoading(false)
        
    }

    return {
        user,
        setUser,
        password,
        setPassword,
        ingresar,
        inputUsuario,
        stateUser,
        mostrarLoading
    }
}
const estiloContenedor = {
    backgroundImage: `url(${imagenFondo})`,
};
export const Login = () => {
    const login = useLogin();
    const [ mostrarContrasena, setMostrarContrasena ] = useState(false)

    const history = useHistory();


    return (
    
        // <div className="grow border border-red-500 h-[100%] container flex justify-center items-center gap-x-10 bg-cover bg-no-repeat" style={estiloContenedor}>
        <div className="grow h-screen   flex justify-center items-center  bg-cover bg-no-repeat" style={estiloContenedor}>
        { login.mostrarLoading && <Loading/>}
            <div className=" grow container  flex justify-center items-center gap-x-10">
                
                {/* <div className="w-[400px]  rounded-2xl mb-[90px] lg:w-[80%] flex flex-col items-center justify-between bg-black bg-opacity-75"> */}
                <div className="w-[400px]  rounded-2xl mb-[90px] lg:w-[80%] flex flex-col items-center justify-between ">
                    
                    <div>
                        <img 
                            src={logo} 
                            alt='' 
                            className=' mb-5 lg:mb-10'
                        />
                    </div>
                    

                    {/* <div className="w-full border-2 border-[#053252] dark:border-[#fff] rounded-3xl" > */}
                    <div className="w-full border-t-2 border-[#053252] dark:border-[#fff] " >
                        
                        {/* <div className='h-[100px]   text-center border-b bg-[#053252] rounded-t-2xl border-[#053252] '>
                            <h2 className="text-[35px] pt-5 font-medium tracking-tight text-white" >
                                Iniciar Sesión
                            </h2>
                        </div> */}

                        <form onSubmit={(e)=> login.ingresar(e)}>

                            <div className="form flex flex-col py-10 text-center w-full">
                                <div className=" mb-8 flex justify-center ">
                                    <div className=" w-[90%]">
                                        <div className=" ">
                                            <div className=" flex rounded  caret-white bg-white ring-2 ring-[#053252] bg-transparent">
                                                <span className="flex items-center rounded-l-xl pl-1 bg-transparent "><i className='text-white fa fa-user px-2'/></span>
                                                <input
                                                    ref={login.inputUsuario}
                                                    onChange={(e) => login.setUser(e.target.value)}
                                                    defaultValue={login.user}
                                                    type="text"
                                                    name="usuario"
                                                    id="usuario"
                                                    autoComplete="username"
                                                    className="h-12 flex-1  bg-transparent py-1.5 pl-1 caret-white text-gray-200 placeholder:text-gray-400 focus:outline-none"
                                                    placeholder="Usuario"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className=" flex justify-center  ">
                                    <div className=" w-[90%]">
                                        <div className="">
                                            <div className=" flex rounded bg-white ring-2 ring-[#053252] bg-transparent">
                                                <span className="flex items-center pl-1 bg-transparent"><i className='text-white fa fa-lock px-2'/></span>
                                                <input
                                                    ref={login.inputUsuario}
                                                    onChange={(e) => login.setPassword(e.target.value)}
                                                    defaultValue={login.password}
                                                    type={mostrarContrasena? "text":"password"}
                                                    name="usuario"
                                                    id="usuario"
                                                    autoComplete="username"
                                                    className="h-12 flex-1   bg-transparent py-1.5 pl-1 caret-white text-gray-200 placeholder:text-gray-400 focus:outline-none"
                                                    placeholder="Contraseña"
                                                />
                                                <button
                                                    type="button"
                                                    className="px-2 py-1 focus:outline-none"
                                                    onClick={()=>setMostrarContrasena(!mostrarContrasena)}
                                                >
                                                    <i className={`text-white fa ${mostrarContrasena ? 'fa-eye-slash' : 'fa-eye'}`} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <div className="content-buttons text-center" >
                                    <button 
                                        type='submit'
                                        className="hover:bg-[#205770] bg-[#053252] text-2xl h-12 w-[70%] lg:w-[80%] text-white py-1 rounded ">
                                        Iniciar Sesión
                                    </button>
                                <div className='dark:text-white text-center m-10' >
                                {/* <BotonNeon estilo='btn-7' type='button' onClick={ ()=>console.log("Contactarme")}  >Contactarme</BotonNeon> */}
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}


