import React, { useState, useEffect } from 'react';
import { Button, IconButton, Grid, Typography, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select, FormControl, InputLabel, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';

export const Cabecera = ({ titulo, onNuevo, searchTerm, handleSearchChange, data, updateFilteredData, searchFields, placeholder, nuevoTexto, filtros }) => {
    const [open, setOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleFilterClick = () => {
        setOpen(true);
    };

    const handleFilterClose = () => {
        setOpen(false);
    };

    const handleFilterChange = (event) => {
        setSelectedFilters({
            ...selectedFilters,
            [event.target.name]: event.target.value
        });
    };

    const applyFilters = () => {
        let filteredData = data;

        Object.keys(selectedFilters).forEach(filterKey => {
            const filterValue = selectedFilters[filterKey].toLowerCase();
            if (filterValue !== 'todos') {
                filteredData = filteredData.filter(item =>
                    item[filterKey].toLowerCase().includes(filterValue)
                );
            }
        });

        updateFilteredData(filteredData);
        setOpen(false);
    };

    useEffect(() => {
        if (Object.keys(selectedFilters).length === 0 || Object.values(selectedFilters).every(val => val === 'todos')) {
            updateFilteredData(data);
        }
    }, [selectedFilters, data, updateFilteredData]);

    return (
        <div style={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: '#07151f', padding: '10px 0',  }}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                <Grid item xs={12} md={3} container alignItems="center">
                    <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                        {titulo}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} container alignItems="center">
                    <TextField
                        variant="outlined"
                        placeholder={placeholder}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        size="big"
                        fullWidth
                        sx={{
                            border: '1px solid #555', // Borde claro
                            borderRadius: '4px',
                            color: '#fff',
                            input: { color: '#fff' }
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: '#aaa' }} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        onClick={handleFilterClick}
                                        style={{
                                            backgroundColor: '#4caf50',
                                            color: '#fff',
                                            borderRadius: '4px',
                                            textTransform: 'none',
                                            padding: '5px 10px'
                                        }}
                                    >
                                        <FilterListIcon style={{ marginRight: 5 }} />
                                        Filtrar
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={onNuevo}
                        sx={{
                            marginLeft: 2,
                            backgroundColor: '#007bff',
                            color: '#fff',
                            height: 48,
                            minWidth: isMobile ? 48 : 'auto', // Ajusta la anchura del botón en mobile
                            padding: isMobile ? 0 : '0 16px', // Ajusta el padding en mobile
                        }}
                    >
                        {isMobile ? null : nuevoTexto} {/* Mostrar solo el icono en mobile */}
                    </Button>
                </Grid>

                <Dialog
                    open={open}
                    onClose={handleFilterClose}
                    PaperProps={{
                        style: {
                            backgroundColor: '#2c2c2c',
                            color: '#fff',
                            width: '600px',
                            padding: '20px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' // Elevación
                        }
                    }}
                >
                    <DialogTitle>Filtros</DialogTitle>
                    <DialogContent>
                        {filtros.map((filtro) => (
                            <FormControl fullWidth margin="normal" key={filtro.nombre}>
                                <InputLabel style={{ color: '#fff' }}>{filtro.label}</InputLabel>
                                <Select
                                    value={selectedFilters[filtro.nombre] || 'todos'}
                                    onChange={handleFilterChange}
                                    name={filtro.nombre}
                                    style={{ color: '#fff', backgroundColor: '#3c3c3c' }}
                                >
                                    <MenuItem value="todos">Todos</MenuItem>
                                    {data.map(item => item[filtro.nombre])
                                        .filter((value, index, self) => self.indexOf(value) === index) // Unicos valores
                                        .map((option, idx) => (
                                            <MenuItem key={idx} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFilterClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={applyFilters} color="primary">
                            Aplicar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </div>
    );
};

