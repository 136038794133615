import { useState, useEffect, useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from './useModal';
import { AuthFetch, Fetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';
import AutoDefault from '../assets/images/autoDefault.jpg'

const vehiculoDefault = {
    CodVehiculo: 0,
    Placa: "",
    Marca: "",
    CodTipoVehiculo: 0,
    Año: "",
    TarjetaPropiedad: "",
    NMotor: "",
    Categoria: "",
    Modelo: "",
    Carroceria: "",
    Combustible: "",
    Serie: "",
    Color: "",
    PesoBruto: "",
    PesoSeco: "",
    Ancho: "",
    Asientos: "",
    Cilindros: "",
    Puertas: "",
    CargaUtil: "",
    Longitud: "",
    Altura: "",
    Pasajeros: "",
    Ejes: "",
    Foto: "",
    BDEscuela: ''

}

const urlVehiculos = process.env.REACT_APP_PROMETHEUS_API + "/vehiculos";

export const useVehiculos = () => {
  const [ vehiculos, setVehiculos ] = useState([]);
  const [ vehiculo, setVehiculo ] = useState(vehiculoDefault);
  const [ isOpenModalRegistrar, openModalRegistrar, closeModalRegistrar ] = useModal();
  const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

  const [ fotoFile, setFotoFile ] = useState(AutoDefault); 
  const [ fotoUrlVehiculo, setFotoUrlVehiculo ] = useState(AutoDefault);

  const {stateUser} = useContext(UserContext)

  useEffect(() => {
      listarVehiculos();
  }, []);

  const listarVehiculos = async () => {
    const response = await AuthFetch({
      url: urlVehiculos + '?'+ new URLSearchParams({
          database : stateUser.BDEscuela,
      })
    });
    if (response.isValid) {
      setVehiculos(response.content);
    } else {
      notify(response.content, 'error');
    }
  }

  const obtenerVehiculo= async (CodVehiculo) => {
    if (CodVehiculo) {
      const response = await AuthFetch({
        url: urlVehiculos + '/obtenerVehiculo?'+ new URLSearchParams({
          cod_vehiculo : CodVehiculo,
          database  : stateUser.BDEscuela,
        })
      });
      if (response.isValid) {
        setVehiculo(response.content);
        setFotoUrlVehiculo(response.content.Foto)
      } else {
        notify(response.content, 'error');
      }
    } else {
      setVehiculo(vehiculoDefault);
      setFotoUrlVehiculo(null)
    }
    openModalRegistrar();
  }

  const vehiculoEliminar = async (CodVehiculo) =>{
    if (CodVehiculo) {
      const response = await AuthFetch({
        url: urlVehiculos + '/' + CodVehiculo
      });

      if (response.isValid) {
        setVehiculo(response.content);
      } else {
        notify(response.content, 'error');
      }
    } else {
      setVehiculo(vehiculoDefault);
    }
    openModalEliminar();
  }

  const eliminarPersona = async (codPersona)=>{
    const response = await AuthFetch({
      url: urlVehiculos + '/eliminarVehiculos?' + new URLSearchParams({
          CodPersona: codPersona
      }),
      method:'PUT'
    })
    notify(response.content, response.isValid? 'success': 'error' )
    if (response.isValid) {
      listarVehiculos()
    }
    closeModalEliminar()
  }
    
  const guardarVehiculoFoto = async () => {
    if (fotoFile != null){
      if (fotoFile.size > 1024 * 1024) {
        notify("Optimizando Foto, vuelva a intentarlo","info")
        try {
            const resizedImage = await resizeImage(fotoFile);
            setFotoFile(resizedImage);
        } catch (error) {
            notify("Error al redimensionar la imagen", "error");
            return;
        }
      }
            
      const fotoData = new FormData();
      fotoData.append('archivo', fotoFile);
      const response = await Fetch({
        url:process.env.REACT_APP_PROMETHEUS_MINIO + '/api/Archivo/subirArchivo?' + new URLSearchParams({
          NombreCarpeta:"vehiculos",
          NombreArchivo: `${vehiculo.Placa}`,
        }),
        headers:{
          contentType: 'image/*'
        },
        method: 'POST',
        body : fotoData
      });
          
      if (response.isValid) {
        const timestamp = new Date().getTime();

          setFotoFile(null)
          vehiculo.Foto=`${process.env.REACT_APP_PROMETHEUS_MINIO}/api/Archivo/verImagen?NombreCarpeta=vehiculos&NombreImagen=${vehiculo.Placa}&t=${timestamp}`
          guardarEditarVehiculo()
        }else{
            notify(response.exceptions[0].description, 'error');
        }
      }else{
        guardarEditarVehiculo()
      }
    }

  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxWidth = 600;
          const maxHeight = 600;
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
          
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              if (blob) {
                if (blob.size > 1024 * 1024) {
                  canvas.toBlob(
                    (compressedBlob) => {
                      if (compressedBlob) {
                        resolve(new File([compressedBlob], file.name, { type: file.type }));
                      } else {
                        reject(new Error("Error al crear el blob de la imagen comprimida"));
                      }
                    },
                    "image/jpeg",
                    0.5 
                  );
                } else {
                  resolve(new File([blob], file.name, { type: file.type }));
                }
              } else {
                reject(new Error("Error al crear el blob de la imagen"));
              }
            },
            "image/jpeg",
            0.5 
          );
        };
        img.onerror = (error) => reject(error);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const guardarEditarVehiculo = async () => {
    const fotoData = new FormData();
    fotoData.append('foto', fotoFile);
    if(fotoFile !== null && fotoFile.size > 1049456){
      notify('El tamaño máximo de la imagen debe ser de 1MB ','error')
      return;
    }else{
      const esGuardar = vehiculo.CodVehiculo == 0;
      const response = await AuthFetch({
        url: urlVehiculos,
        method: esGuardar ? 'POST' : 'PUT',
        body : JSON.stringify({
          ...(!esGuardar && { CodVehiculo: vehiculo.CodVehiculo }),
          Placa:vehiculo.Placa,
          Marca:vehiculo.Marca,
          CodTipoVehiculo: vehiculo.CodTipoVehiculo,
          Año:vehiculo.Año,
          TarjetaPropiedad:vehiculo.TarjetaPropiedad,
          NMotor:vehiculo.NMotor,
          Categoria:vehiculo.Categoria,
          Modelo:vehiculo.Modelo,
          Carroceria:vehiculo.Carroceria,
          Combustible:vehiculo.Combustible,
          Serie:vehiculo.Serie,
          Color:vehiculo.Color,
          PesoBruto:vehiculo.PesoBruto,
          PesoSeco:vehiculo.PesoSeco,
          Ancho:vehiculo.Ancho,
          Asientos:vehiculo.Asientos,
          Cilindros:vehiculo.Cilindros,
          Puertas:vehiculo.Puertas,
          CargaUtil:vehiculo.CargaUtil,
          Longitud:vehiculo.Longitud,
          Altura:vehiculo.Altura,
          Pasajeros:vehiculo.Pasajeros,
          Ejes:vehiculo.Ejes,
          Foto:vehiculo.Foto,
          BDEscuela:stateUser.BDEscuela
        })
      });
      notify(response.content, response.isValid ? 'success' : 'error');
      if (response.isValid) {
        setVehiculo(vehiculoDefault);
        setFotoUrlVehiculo("");
        await listarVehiculos();
        closeModalRegistrar()
      }
    }
  }

  const editarValorVehiculo = (key, value) => {
    setVehiculo(vehiculo => {
      return {
        ...vehiculo,
        [key]: value
      }
    });
  }

    
  return {
    vehiculos,
    listarVehiculos,
    guardarEditarVehiculo,
    vehiculo, 
    editarValorVehiculo, 
    obtenerVehiculo, 
    isOpenModalRegistrar, 
    closeModalRegistrar, 
    setFotoFile,
    eliminarPersona, 
    isOpenModalEliminar, 
    openModalEliminar,
    closeModalEliminar, 
    vehiculoEliminar,
    fotoUrlVehiculo,
    setFotoUrlVehiculo, 
    guardarVehiculoFoto
  }
}