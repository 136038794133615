import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export const CustomSelect = ({ label, value, onChange, options = [], ...props }) => {
    return (
        <FormControl fullWidth variant="filled" sx={{ bgcolor: '#374151', borderRadius: '4px' }}>
            <InputLabel sx={{ color: '#ffffff' }}>{label}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                sx={{
                    color: '#ffffff',
                    '.MuiSvgIcon-root': {
                        color: '#ffffff', 
                    },
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            bgcolor: '#374151',
                            '& .MuiMenuItem-root': {
                                color: '#ffffff',
                            },
                        },
                    },
                }}
                {...props}
            >
                <MenuItem value={0} sx={{ color: '#ffffff' }}>-- Seleccione --</MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ color: '#ffffff' }}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
