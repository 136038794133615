import React, { useState } from 'react';

export const SearchTable = ({ data, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    // Lógica de búsqueda aquí
    const filteredResults = data.filter(item =>
      item.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );
        console.log("filteredResults -> ", filteredResults)
    // Llama a la función proporcionada con los resultados filtrados
    onSearch(filteredResults);
  };

  return (
    <div>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button onClick={()=>handleSearch()}>Buscar</button>
    </div>
  );
}

