import { useState, useEffect, useContext } from "react";
import { notify } from "../utils/utils";
import { AuthFetch, Fetch } from "../services/api";
import { useModal } from "./useModal";
import { useSpinner } from "./useSpinner";
import { UserContext } from "../context/provider/UserProvider";

const urlTipoVehiculo = process.env.REACT_APP_PROMETHEUS_API + "/tipoVehiculo";

export const useTiposVehiculos = () => {
    const { stateUser , signOut } = useContext(UserContext);
    const [ tiposVehiculos, setTiposVehiculos ] = useState([]);
    
    useEffect(() => {
        listarTiposVehiculos();
    }, []);

    const listarTiposVehiculos = async () => {
        console.log("INGRESO A LA FUNCION LISTAR USAURIOS ")
        const response = await AuthFetch({
            url: urlTipoVehiculo + '?'+ new URLSearchParams({
                database  : stateUser.BDEscuela,
            })
        });
        if (response.isValid) {
            console.log("RESPONSE ROLES -> ", response.content)
            setTiposVehiculos(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    return {
        tiposVehiculos
    };
}