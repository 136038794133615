import React, { useRef, useEffect, useContext } from 'react';
import { useHistory, NavLink } from "react-router-dom";
import $ from 'jquery';
import { UserContext } from '../../../context/provider/UserProvider';
import logoDark from '../../../assets/images/em-dark.fw.png';

export const Menu = React.forwardRef(({ isOpen, toggleContentMenu, darkMode }, ref) => {
    const { stateUser } = useContext(UserContext);
    const splitRef = useRef();
    const history = useHistory();

    const redireccionar = (ruta) => {
        history.push(ruta);
    };

    useEffect(() => {
        const li = document.getElementsByClassName("li-nav-div");
        for (let i = 0; i < li.length; i++) {
            const linkSubMenu = li[i].parentNode.querySelectorAll('.li-a-submenu');
            let up = true;

            for (let j = 0; j < linkSubMenu.length; j++) {
                const ulSubMenu = linkSubMenu[j].parentNode.parentNode.parentNode;
                if (linkSubMenu[j].classList.contains("active")) {
                    const liNavDiv = ulSubMenu.parentNode;
                    liNavDiv.querySelector('.li-nav-div').children[0].classList.add("active");
                    up = false;
                }
            }

            if (up) $(li[i]).parent().children('ul').slideUp();
        }
    });

    const cerrarMenu = () => {
        toggleContentMenu(!isOpen);
    };

    const toggleMenu = (e) => {
        let element = e.target;
        while (!element.classList.contains('li-nav-div')) {
            element = element.parentNode;
        }
        const menuParents = $('.li-nav-div');
        for (let i = 0; i < menuParents.length; i++) {
            const menuParent = menuParents[i];
            if (element !== menuParent) {
                menuParent.children[0].classList.remove('active');
                $(menuParent.parentNode.children[1]).slideUp();
            }
        }
        if (element.children[0].classList.contains('active')) {
            $(element.parentNode.querySelector('.ul-submenu')).slideUp();
        } else {
            $(element.parentNode.querySelector('.ul-submenu')).slideDown();
        }
        element.children[0].classList.toggle('active');
    };

    return (
        <div className={`border-r flex menu ${darkMode ? 'dark-mode' : ''} ${isOpen ? 'open' : 'closed'}`}>
            <ul ref={ref} className="ul-nav lg:py-[20px] py-0">
            <div className={`${isOpen ? 'flex' : ' flex'} flex pb-2 mb-2 border-b justify-between items-center`}>
                <img
                    onClick={() => toggleContentMenu(!isOpen)}
                    className={`${isOpen ? 'ml-2' : 'ml-0 hover:bg-[#1476bd56] cursor-pointer'} mt-1 w-[105px] `}
                    src={logoDark}
                    alt="Workflow"
                />
                <button
                    onClick={cerrarMenu}
                    type="button"
                    className="hover:text-white hover:bg-[#1476bd56] text-gray-200 inline-flex z-50 items-center justify-center p-1 rounded h-full h-full "
                    aria-controls="mobile-menu"
                    aria-expanded="false"
                >
                    <i
                        className={` text-gray-100 ${isOpen ? 'fa fa-angle-left text-3xl' : 'fa fa-angle-right text-2xl absolute ml-[-8px] hover:bg-[#1476bd56]'} cursor-pointer  h-10 text-gray-300  rounded h-full flex items-center justify-center`}
                    ></i>
                </button>
            </div>

                    
                    {/* <li id="li" className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                    <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Usuarios</span>
                                    <i className="icon-menu ml-[-6px] ml-[-6px] fas fa-sort-down"></i>
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu p-0" >
                            <li id="li-submenu" className="waves-effect ul-nav-li-submenu" onClick={()=>redireccionar("/registros-usuarios")}>
                                <div className="li-nav-div-submenu">
                                    <NavLink to={'/registros-usuarios'} className="nav-link li-a-submenu" >
                                        <i className="fa fa-user  " aria-hidden="true"></i>
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `} >Registros</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li id="li-submenu" className="waves-effect ul-nav-li-submenu" onClick={()=>redireccionar("/roles")}>
                                <div className="li-nav-div-submenu">
                                    <NavLink to={'/roles'} className="nav-link li-a-submenu" >
                                        <i className="fa fa-users " aria-hidden="true"></i>
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Roles</span>
                                    </NavLink>
                                </div>
                            </li>
                        </ul>
                        
                    </li> */}

                    <li id="li" className="waves-effect ul-nav-li active-li" onClick={()=>redireccionar("/registros-usuarios")}>
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                    <NavLink to={'/registros-usuarios'} className="text-menu">
                                        <i className="fa fa-user" aria-hidden="true"></i>
                                        <span className={`${isOpen ? '' : ' hidden '} mx-2`}>Usuarios</span>
                                    </NavLink>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li id="li" className="waves-effect ul-nav-li active-li" onClick={()=>redireccionar("/roles")}>
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                    <NavLink to={'/roles'} className="text-menu">
                                        <i className="fa fa-users" aria-hidden="true"></i>
                                        <span className={`${isOpen ? '' : ' hidden '} mx-2`}>Roles</span>
                                    </NavLink>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li id="li" className="waves-effect ul-nav-li active-li" onClick={()=>redireccionar("/matricula")}>
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                    <NavLink to={'/matricula'} className="text-menu">
                                        <i className="fa fa-address-card" aria-hidden="true"></i>
                                        <span className={`${isOpen ? '' : ' hidden '} mx-2`}>Matrícula</span>
                                    </NavLink>
                                </div>
                            </a>
                        </div>
                    </li>




                    

                    <li id="li" className="waves-effect ul-nav-li active-li" onClick={()=>redireccionar("/vehiculos")}>
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                <i className="fa fa-car" aria-hidden="true"></i>
                                <NavLink to={'/vehiculos'} className="" >

                                <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Vehículos</span>
                                    {/* <i className="icon-menu ml-[-6px] fas fa-sort-down"></i> */}
                                </NavLink>
                                </div>
                            </a>
                        </div>
                        {/* <ul className="ul-submenu p-0">
                            <li id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">

                                    <NavLink to={'/registros-vehiculos'} className="nav-link li-a-submenu" >
                                        <i className="fa fa-car" aria-hidden="true"></i>
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Unidades</span>
                                    </NavLink>
                                </div>
                            </li>
                        </ul> */}
                        
                    </li>

                    <li id="li" className="waves-effect ul-nav-li active-li" onClick={()=>redireccionar("/paquetes")}>
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                    <i class="fa fa-street-view" aria-hidden="true"></i>
                                    <NavLink to={'/paquetes'} className="" >
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Paquetes</span>
                                        {/* <i className="icon-menu ml-[-6px] ml-[-6px] fas fa-sort-down"></i> */}
                                    </NavLink>
                                </div>
                            </a>
                        </div>
                    </li>


                    <li id="li" className="waves-effect ul-nav-li active-li" onClick={()=>redireccionar("/documentos")}>
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                    <i class="fa fa-id-card" aria-hidden="true"></i>
                                    <NavLink to={'/documentos'} className="" >
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Documentos</span>
                                    </NavLink>

                                </div>
                            </a>
                        </div>
                    </li>

                    <li id="li" className="waves-effect ul-nav-li active-li" onClick={()=>redireccionar("/programacion")}>
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                    <NavLink to={'/programacion'} className="" >
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Programación</span>
                                        {/* <i className="icon-menu ml-[-6px] ml-[-6px] fas fa-sort-down"></i> */}
                                    </NavLink>

                                </div>
                            </a>
                        </div>
                    </li>

                    <li id="li" className="waves-effect ul-nav-li active-li" onClick={()=>redireccionar("/monitoreo")}>
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                    <i class="fa fa-car" aria-hidden="true"></i>
                                    <NavLink to={'/monitoreo'} className="" >
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Monitoreo</span>
                                        {/* <i className="icon-menu ml-[-6px] ml-[-6px] fas fa-sort-down"></i> */}
                                    </NavLink>

                                </div>
                            </a>
                        </div>
                    </li>
                    
                    <li id="li" className="waves-effect ul-nav-li active-li" onClick={()=>redireccionar("/reportes-recorridos")}>
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                    <NavLink to={'/reportes-recorridos'} className="" >
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Reportes</span>
                                        {/* <i className="icon-menu ml-[-6px] ml-[-6px] fas fa-sort-down"></i> */}
                                    </NavLink>

                                </div>
                            </a>
                        </div>
                    </li>


                    <li id="li" className="waves-effect ul-nav-li active-li" >
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Reportes</span>
                                    <i className="icon-menu ml-[-6px] fas fa-sort-down"></i>
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu p-0">
                            <li id="li-submenu" className="waves-effect ul-nav-li-submenu" onClick={()=>redireccionar("/reportes-recorridos")}>
                                <div className="li-nav-div-submenu">
                                    <NavLink to={'/reportes-recorridos'} className="nav-link li-a-submenu" >
                                        <i class="fa fa-map" aria-hidden="true"></i>
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Recorridos</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li id="li-submenu" className="waves-effect ul-nav-li-submenu" onClick={()=>redireccionar("/reporte-instructores")}>
                                <div className="li-nav-div-submenu">
                                    <NavLink to={'/reporte-instructores'} className="nav-link li-a-submenu" >
                                        <i class="fa fa-users" aria-hidden="true"></i>
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Instructores</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li id="li-submenu" className="waves-effect ul-nav-li-submenu" onClick={()=>redireccionar("/reporte-matriculas")}>
                                <div className="li-nav-div-submenu">
                                    <NavLink to={'/reporte-matriculas'} className="nav-link li-a-submenu" >
                                        <i class="fa fa-address-book" aria-hidden="true"></i>
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Matriculas</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li id="li-submenu" className="waves-effect ul-nav-li-submenu" onClick={()=>redireccionar("/reporte-clases")}>
                                <div className="li-nav-div-submenu">
                                    <NavLink to={'/reporte-clases'} className="nav-link li-a-submenu" >
                                        <i class="fa fa-address-book" aria-hidden="true"></i>
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Clases</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li id="li-submenu" className="waves-effect ul-nav-li-submenu" onClick={()=>redireccionar("/reporte-asistencias")}>
                                <div className="li-nav-div-submenu">
                                    <NavLink to={'/reporte-asistencias'} className="nav-link li-a-submenu" >
                                        <i class="fa fa-address-book" aria-hidden="true"></i>
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Asistencias</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li id="li-submenu" className="waves-effect ul-nav-li-submenu" onClick={()=>redireccionar("/reporte-vehiculos")}>
                                <div className="li-nav-div-submenu">
                                    <NavLink to={'/reporte-vehiculos'} className="nav-link li-a-submenu" >
                                        <i class="fa fa-address-book" aria-hidden="true"></i>
                                        <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Vehiculos</span>
                                    </NavLink>
                                </div>
                            </li>
                        </ul>
                    </li>

                    <li id="li" className="waves-effect ul-nav-li active-li" onClick={()=>redireccionar("/sucursales")}>
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                <NavLink to={'/sucursales'} className="" >

                                <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
                                <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Sucursales</span>
                                    {/* <i className="icon-menu ml-[-6px] fas fa-sort-down"></i> */}
                                </NavLink>
                                </div>

                            </a>
                        </div>
                        
                      </li>
                      

                      
                    <li id="li" className="waves-effect ul-nav-li active-li" onClick={()=>redireccionar("/configuracion")}>
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                <NavLink to={'/configuracion'} className="" >

                                <i class="fa fa-cogs" aria-hidden="true"></i>
                                <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Configuración</span>
                                    {/* <i className="icon-menu ml-[-6px] fas fa-sort-down"></i> */}
                                </NavLink>
                                </div>

                            </a>
                        </div>
                        
                    </li>
                    <li id="li" className="waves-effect ul-nav-li active-li" onClick={()=>redireccionar("/soporte")}>
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-start">
                                <NavLink to={'/soporte'} className="" >

                                <i class="fa fa-headset" aria-hidden="true"></i>
                                <span className={`${isOpen? '': ' hidden '} mx-2 text-menu `}>Soporte</span>
                                    {/* <i className="icon-menu ml-[-6px] fas fa-sort-down"></i> */}
                                </NavLink>
                                </div>

                            </a>
                        </div>
                        
                    </li>
                </ul>
            </div>

    )
})