import { UserTypes } from "../types/UserTypes"

export const UserReducers = (state, action) => {
  switch (action.type) {
    case UserTypes.SIGN_IN:
      return {
        ...state,
        ...action.payload
      }
    case UserTypes.SIGN_OUT:
      return {
        ...state,
        BDEscuela: "",
        CodRol: "",
        FechaNacimiento: "",
        apellidoMaterno: "",
        apellidoPaterno: "",
        celular: "",
        codEmpresa: "",
        codUsuario: "",
        direccion: "",
        email: "",
        empresa: "",
        foto: "",
        nomUsuario: "",
        nombre: "",
        tiempoExpiraMiliSegundos: "",
        token: ""
      }
      case UserTypes.CAMBIAR_CONFIGURACION:
        return {
          ...state,
              actualizoConfiguracion: !state.actualizoConfiguracion
        }
      default:
        throw new Error()
    }
}
