import React, { useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ClickAwayListener, Menu, MenuItem, IconButton, Avatar, ListItemIcon, Badge } from '@mui/material';
import { Brightness4, Brightness7, Person, ExitToApp, Mail, Settings, Notifications } from '@mui/icons-material';
import { UserContext } from '../../../context/provider/UserProvider';
import { SocketContext } from '../../../context/provider/SocketProvider';

export const Header = ({ toggleDarkMode, darkMode }) => {
    const { stateUser, signOut } = useContext(UserContext);
    const { mqttDisconnect } = useContext(SocketContext);
    const history = useHistory();
    const fotoUrl = `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOIL45mNyKyZR5EXbVjTNJj894jTz5qR7BpCw_-0_UIYxN8K-kcTMCmY6PiVoqVtMJt6Q&usqp=CAU`;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        mqttDisconnect();
        signOut();
        history.push('/login');
    };

    const handleProfile = () => {
        history.push('/perfil');
        handleClose();
    };

    return (
        <div className="header z-50 bg-black bg-opacity-20  flex justify-end items-center px-4 ">
            {/* <IconButton onClick={toggleDarkMode} color="inherit">
                {darkMode ? <Brightness7 /> : <Brightness4 />}
            </IconButton> */}
            <div className="flex items-center">
                <IconButton color="inherit">
                    <Badge badgeContent={4} color="error">
                        <Notifications />
                    </Badge>
                </IconButton>
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <IconButton onClick={handleClick} color="inherit">
                            <Avatar src={stateUser.foto || fotoUrl} sx={{ border: '2px solid white' }} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    mt: 1.5,
                                    bgcolor: '#162542', // Cambiar color de fondo
                                    color: 'white', // Color del texto
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 20,
                                        width: 20,
                                        height: 20,
                                        bgcolor: '#162542',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={handleProfile}>
                                <Avatar src={stateUser.foto || fotoUrl} /> Perfil
                            </MenuItem>
                            <MenuItem onClick={() => console.log('CLICK MENSAJES')}>
                                <ListItemIcon>
                                    <Mail fontSize="small" sx={{ color: 'white' }} />
                                </ListItemIcon>
                                Mensajes
                            </MenuItem>
                            <MenuItem onClick={() => console.log('CLICK EN CONFIGURACION')}>
                                <ListItemIcon>
                                    <Settings fontSize="small" sx={{ color: 'white' }} />
                                </ListItemIcon>
                                Configuración
                            </MenuItem>
                            <MenuItem onClick={handleSignOut}>
                                <ListItemIcon>
                                    <ExitToApp fontSize="small" sx={{ color: 'white' }} />
                                </ListItemIcon>
                                Cerrar Sesión
                            </MenuItem>
                        </Menu>
                    </div>
                </ClickAwayListener>
            </div>
        </div>
    );
};
