import React, { useState } from 'react';
import '../../../assets/css/layoutMaster.css';
import { Menu } from './Menu';
import { Header } from './Header';

function debounce(fn, ms) {
    let timer;
    return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}

export const LayoutMaster = ({ children }) => {
    const [isOpen, setOpen] = useState(true); 
    const [darkMode, setDarkMode] = useState(false);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const toggleContentMenu = () => {
        setOpen(!isOpen);
    };

    return (
        <>
            <div className={`dashboard overflow-hidden ${isOpen ? 'main-open' : 'main-expanded'}`}>
                <Header openMenu={toggleContentMenu} toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
                <Menu
                    isOpen={isOpen}
                    toggleContentMenu={toggleContentMenu}
                    darkMode={darkMode}
                />
                <div className={` h-[95%] px-2 main containerScroll ${isOpen ? 'main-open' : 'main-expanded'}`}>
                    {children}
                </div>
            </div>
        </>
    );
};
