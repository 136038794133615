const google = window.google;
const { DirectionsService } = window.google.maps;
export class GoogleMap {

    latDefault = -12.069538270159754;
    lngDefault = -77.047119140625;
    zoomDefault = 13;
    concatStringRoute = "-2";
    _colorDefaultConcurrente = "#FF4634"
    // _colorDefaultConcurrente = "#000000"
    _colorPuntos = {
        selected: "#005AFF",
        notSelected: "#3A9BFF"
    }

    constructor() {
        this.map = "";
        this.mapDiv = "";
        this.marcadores = [];
        this.polilineas = [];
        this.circulos = [];
        this.poligonos = {};
        this.tramos = [];

        this.infoWindow = null
    }

    inicializarMapa(mapDiv, {
        zoom
    } = {}) {
        this.mapDiv = mapDiv;
        this.map = new google.maps.Map(mapDiv, {
            zoom: zoom || this.zoomDefault,
            center: {
                lat: this.latDefault,
                lng: this.lngDefault
            },
        });
    }

    crearMarcador({ latLng, icon, animation, visible,zoom ,color, clickable,draggable}) {
        console.log("googlemaps.js -> CREAR MARCADOR")
        const marcador = new window.google.maps.Marker({
            position: latLng || { lat: this.latDefault, lng: this.lngDefault},
            icon: icon == "puntos"? {
                    url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><circle cx="20" cy="20" r="7" fill="${color}" stroke="white" /></svg>`),
                    anchor: new google.maps.Point(20, 20),
                    scaledSize: new google.maps.Size(40, 40), // Tamaño del marcador
                    labelOrigin: new google.maps.Point(20, 20), // Punto de origen de la etiqueta
                } : icon== null? null : icon,
            map: this.map,

            animation: animation?  google.maps.Animation.BOUNCE : null,
            visible: visible || true,
            zoom: zoom || 12,
            clickable: clickable || true,
            zIndex: 100,
            draggable: draggable || false
        });
        this.marcadores.push(marcador);

        return marcador;
    }

    crearMarcadorGif({icon,latLng}){
        const marcador = new window.google.maps.Marker({
            icon: {
                url: icon,
                anchor: new google.maps.Point(70, 70),
                scaledSize: new google.maps.Size(140, 140), // Tamaño del marcador
                labelOrigin: new google.maps.Point(70, 70), // Punto de origen de la etiqueta
            },
            position: latLng,
            map: this.map,
            visible: true,
            zoom: 12,
            zIndex: 99,
            opacity: 0.6
        })

        this.marcadores.push(marcador)
        return marcador
    }

    quitarMarcador(marcador) {
        this.marcadores[marcador].setMap(null);
        this.marcadores = this.marcadores.filter(m => m !== marcador);
    }

    quitarMarcadorEspecifico(marcadorE){
        this.marcadores.map((data) => {
            if(data == marcadorE){
                data.setMap(null)
            }
        })
        this.marcadores = this.marcadores.filter(elem => elem != marcadorE)
    }

    quitarTodosLosMarcadoresExcepto(marcador){
        this.marcadores.map((data) => {
            if(data != marcador){
                data.setMap(null)
            }
        })
        this.marcadores = this.marcadores.filter(elem => elem == marcador)
    }

    quitarTodosLosMarcadores() {
        this.marcadores.forEach(element => {
            element.setMap(null)
        })
        this.marcadores = []
    }


    crearObjLatLng(latitud, longirud){
        return  new google.maps.LatLng( latitud, longirud);
    }

    //Metodos para las rutas
    crearPolilinea({
        listLatLng, color, strokeWeight, strokeOpacity
    }) {

        if (!listLatLng) {
            // alert("Es obligatorio ingrear una latitud y una longitud");
            console.log("Es obligatorio ingrear una latitud y una longitud");
            return;
        }

        const opciones = {
            path: listLatLng,
            geodesic: true,
            strokeColor: color || "#0059FF",
            strokeOpacity: strokeOpacity || 8,
            strokeWeight: strokeWeight || 0.3,
        }

        const polilinea = new google.maps.Polyline(opciones);
        polilinea.setMap(this.map);
        this.polilineas.push(polilinea);

        return polilinea
    }

    quitarPolilinea(polilinea) {
        this.polilineas[polilinea].setMap(null);
        delete this.polilineas[polilinea];
    }

    quitarTodosLasPolilineas() {

        for (let p in this.polilinea) {
            this.polilineas[p].setMap(null);
        }

        this.polilineas = {}
    }

    crearCirculo({
        latLng, radio, color, editable, draggable, anchoBorde, opacidadBorde, opacidadFondo, colorFondo
    }) {

        if (!latLng) {
            alert("Es obligatorio ingrear una latitud y una longitud");
            return;
        }

        const circulo = new google.maps.Circle({
            center: latLng,
            strokeColor: color,
            strokeOpacity: opacidadBorde || 1,
            strokeWeight: anchoBorde || 4,
            fillColor: colorFondo || color || "#0059FF",
            fillOpacity: opacidadFondo || 0.2,
            radius: radio || 5,
            editable: editable || false,
            draggable: draggable || false,
            map: this.map,
            zIndex: 10,
        })

        this.circulos.push(circulo);
    }

    quitarCirculo(circulo) {
        this.circulos[circulo].setMap(null);
        this.circulos = this.circulos.filter(c => c !== circulo);
    }

    crearInfowindow({contenido,latLng}) {
        // if (!latLng) {
        //     alert("Es obligatorio ingrear una latitud y una longitud");
        //     return;
        // }

        contenido = contenido || ''

        const infowindow = new google.maps.InfoWindow({
            content: contenido,
            // position: latLng,
        });

        return infowindow;
    }

    //Metodos para los poligonos
    crearPoligono({
        id,
        listLatLng,
        color,
        opacidad,
        opacidadFondo,
        editable,
        draggable
    }) {
        if (!listLatLng) {
            // alert("Es obligatorio ingrear una latitud y una longitud");
            return;
        }

        const opciones = {
            paths: listLatLng,
            strokeColor: color || "#FF0000",
            strokeOpacity: opacidad || 0.8,
            strokeWeight: 2,
            fillColor: color || "#FF0000",
            fillOpacity: opacidadFondo || 0.35,
            editable: editable || false,
            draggable: draggable || false
        };

        this.poligonos[id] = new google.maps.Polygon(opciones);
        this.poligonos[id].setMap(this.map);

        return this.poligonos[id];

    }

    quitarPoligono(poligonoId) {
        this.poligonos[poligonoId].setMap(null);
        delete this.poligonos[poligonoId];
    }

    quitarTodosLosPoligonos() {
        for (let p in this.poligonos) {
            this.poligonos[p].setMap(null);
        }

        this.poligonos = {}
    }

    quitarTodosLosPoligonosExcepto(poligono){
        let nombre = ''
        for (let p in this.poligonos) {
            if(poligono != this.poligonos[p]){
                this.poligonos[p].setMap(null);
            }else{
                nombre = p
            }
        }

        this.poligonos = {}
        this.poligonos[nombre] = poligono
    }

    updateCenterAndZoom(flightPlanCoordinates) {
        const sizeCoordinates = flightPlanCoordinates.length;

        const createMarkers = (point) => {
            return new google.maps.Marker({
                position: new google.maps.LatLng(point.lat, point.lng)
            });
        }

        function createBoundsForMarkers(markers) {
            var bounds = new google.maps.LatLngBounds();
            markers.forEach(marker => {
                bounds.extend(marker.getPosition());
            });
            return bounds;
        }

        let markers = [];

        flightPlanCoordinates.forEach(point => {
            markers.push(createMarkers(point));
        });

        const bounds = createBoundsForMarkers(markers);

        const getNewZoom = () => {
            const WORLD_DIM = { height: 256, width: 256 };
            const ZOOM_MAX = 21;

            const latRad = (lat) => {
                const sin = Math.sin(lat * Math.PI / 180);
                const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
                return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
            }

            const zoom = (mapPx, worldPx, fraction) => {
                return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
            }

            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();

            const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

            const lngDiff = ne.lng() - sw.lng();
            const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

            const latZoom = zoom(this.mapDiv.clientHeight, WORLD_DIM.height, latFraction);
            const lngZoom = zoom(this.mapDiv.clientWidth, WORLD_DIM.width, lngFraction);

            const newZoom = Math.min(latZoom, lngZoom, ZOOM_MAX);
            return newZoom;
        }

        const getNewCenter = () => {
            return {
                lat: bounds.getCenter().lat(),
                lng: bounds.getCenter().lng()
            };
        }

        this.map.setCenter(getNewCenter());
        this.map.setZoom(sizeCoordinates === 1 ? 17.5 : getNewZoom())

        return getNewCenter();
    }

    crearRutaEntreMarcadores(marcadorOrigen, marcadorDestino) {
        console.log("marcadorOrigen -> ",marcadorOrigen)
        console.log("marcadorDestino -> ",marcadorDestino)
        if (!marcadorOrigen || !marcadorDestino) {
            console.error('No se pueden crear rutas sin marcadores de origen y destino.');
            return;
        }

        const directionsService = new DirectionsService();

        const request = {
            origin: marcadorOrigen.getPosition(),
            destination: marcadorDestino.getPosition(),
            travelMode: 'DRIVING', 
        };

        directionsService.route(request, (result, status) => {
            if (status === 'OK') {
                const directionsRenderer = new window.google.maps.DirectionsRenderer({
                    markerOptions: {
                        visible: false
                    }
                });
                directionsRenderer.setMap(this.map);
                directionsRenderer.setDirections(result);

                //************************************************** */
                const path = result.routes[0].overview_path;
                const midPointIndex = Math.floor(path.length / 2);
                const midPointPosition = path[midPointIndex];

                const duration = result.routes[0].legs[0].duration.text;

                if(this.infoWindow){
                    this.infoWindow.close()
                }

                const infoWindow = new window.google.maps.InfoWindow({
                    content: `${duration}`,
                    maxWidth: 100,
                });

                infoWindow.setPosition(midPointPosition);
                infoWindow.open(this.map);
                this.infoWindow = infoWindow
                //************************************************** */

                this.tramos.push(directionsRenderer)    //almacena el tramo
            } else {
                console.error(`Error al obtener direcciones: ${status}`);
            }
        });
    }

    quitarTodosLosTramos(){
        this.tramos.map((data,i) => {
            data.setMap(null)
        })
    }
}