import React from 'react';
import { LabelTituloGeneral } from './LabelTituloGeneral';
import { SearchTable }  from '../../components/utils/SearchTable';

export const ContenedorParametros = ({
    titulo = '',
    children,
    data = null
}) => {
    return (
        <>
            <div className="flex border" style={{alignItems: 'center',boxShadow: '0 10px 10px 0 rgba(255,255,255,1)'}}>
                <div className=" min-h-[48px] h-auto  flex items-center px-[15px] justify-between flex-wrap	">
                        {children}   
                    <LabelTituloGeneral titulo={titulo || 'Titulo'}/>  
                    <SearchTable data={data}/>
                </div>
            </div>
            <hr className='mt-4'/>
        </>
    )
}