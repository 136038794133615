import React, { useEffect, useMemo, useReducer,useState } from 'react';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import usePermisos from '../../../hooks/usePermisos';
import { useTipoUsuario } from '../../../hooks/usuarios/useTipoUsuario';

export const Permisos = () => {
    const { listaPermisos, cambiarEstadoPermiso } = usePermisos();

    const [tipoUsuarios] = useTipoUsuario()

    const [valueSelectUsuario, setValueSelectUsuario] = useState('')

    const [selectedOptions,setSelectedOptions] = useState([])

    const classNameInput = "containerScroll h-[30px] text-white rounded px-4 w-[250px] lg:w-[200px] bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"

    useEffect(() => {
        if(tipoUsuarios.length != 0){
            setValueSelectUsuario(tipoUsuarios[0].nomUsuarioTipo)
        }
    },[tipoUsuarios])

    return (
        <>
            <ContenedorParametros
                titulo="Permisos"
                >
                <i title='Tipo de Usuario' className="fa fa-user" aria-hidden="true"></i>
                <select value={valueSelectUsuario} className={classNameInput} onChange={(e) => setValueSelectUsuario(e.target.value)}>
                    {
                        tipoUsuarios?.map(function(data,i){
                            return  <option key={i+1} value={data.nomUsuarioTipo}>{data.nomUsuarioTipo}</option>
                        })
                    }
                </select>
            </ContenedorParametros>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Permisos / Tipo de Usuarios</th>
                            <th></th>
                            {/* {
                                listaPermisos?.usuarioTipos?.map((data,i) => {
                                    return (
                                        <th key={i++} className="max-w-[10px]">{ data.nomUsuarioTipo }</th>
                                    )
                                })
                            } */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listaPermisos?.permisos?.map((data,i) => {

                                let valueCheck = false
                                if(data.usuarioTipos[valueSelectUsuario] == 1){
                                    valueCheck = true
                                }

                                return (
                                    <tr key={i++}>
                                        <td>{ data.nomPermiso }</td>
                                        <td className='w-12'>
                                            <input 
                                                checked={valueCheck} 
                                                type='checkbox' 
                                                onChange={(e) => {
                                                    cambiarEstadoPermiso(e, data.codPermiso, listaPermisos.usuarioTipos.find(elem => elem.nomUsuarioTipo == valueSelectUsuario).codUsuarioTipo)
                                                }
                                            } />
                                        </td>

                                        {/* {
                                            listaPermisos?.usuarioTipos?.map((ut,j) => {
                                                return (
                                                    <td key={j++} className="text-center">
                                                        <input type="checkbox" onChange={(e) => cambiarEstadoPermiso(e, data.codPermiso, ut.codUsuarioTipo)} defaultChecked={data.usuarioTipos[ut.nomUsuarioTipo] == 1 ? true : false} />
                                                    </td>
                                                )
                                            })
                                        } */}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}