import  bannerPrincipal  from "../../../assets/images/em.fw.png"


export const DashBoard = () => {
    

    return (
      <>
          <div className="flex justify-center mt-[5%] ">
            <img className="w-[300px] opacity-10" src={bannerPrincipal} alt="" />
                {/* <div>DashBoard</div> */}
          </div>
        </>
    )


}