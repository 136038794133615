import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap } from '../../../utils/googlemaps';
import iconoUnidad from '../../../assets/images/iconoUnidad.png';
import { Card, CardContent, Typography } from '@mui/material';

export const Monitoreo = () => {
  const mapRef = useRef(null);
  const googleMapInstance = useRef(null);
  const [unidades, setUnidades] = useState([
    { id: 1, placa: 'ABC123', lat: -12.069538270159754, lng: -77.034998, fecha: new Date() },
    { id: 2, placa: 'DEF456', lat: -12.079298, lng: -77.046453, fecha: new Date() },
    { id: 3, placa: 'GHI789', lat: -12.062891, lng: -77.041982, fecha: new Date() },
    { id: 4, placa: 'JKL012', lat: -12.067545, lng: -77.055367, fecha: new Date() },
    { id: 5, placa: 'MNO345', lat: -12.058654, lng: -77.040034, fecha: new Date() }
  ]);

  useEffect(() => {
    // Asegúrate de que el script de Google Maps está cargado
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_GOOGLE_MAPS_API_KEY`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          initializeMap();
        };
        document.head.appendChild(script);
      } else {
        initializeMap();
      }
    };

    // Inicializar el mapa cuando Google Maps esté listo
    const initializeMap = () => {
      if (mapRef.current && !googleMapInstance.current) {
        googleMapInstance.current = new GoogleMap();
        googleMapInstance.current.inicializarMapa(mapRef.current);

        // Agregar los marcadores al mapa
        unidades.forEach(unidad => {
          const marker = googleMapInstance.current.crearMarcador({
            latLng: { lat: unidad.lat, lng: unidad.lng },
            icon: iconoUnidad,
            visible: true,
          });

          // Crear InfoWindow
          const infoWindow = googleMapInstance.current.crearInfowindow({
            contenido: `
              <div style="background-color: #333333; padding: 10px; border-radius: 8px; color: #ffffff;">
                <strong>Placa:</strong> ${unidad.placa}<br />
                <strong>Fecha y Hora:</strong> ${unidad.fecha.toLocaleString('es-ES')}
              </div>
            `,
            latLng: { lat: unidad.lat, lng: unidad.lng }
          });

          // Mostrar InfoWindow al hacer clic en el marcador
          marker.addListener('click', () => {
            infoWindow.open(googleMapInstance.current.map, marker);
          });

          // Guardar referencia al marcador en el objeto de unidad
          unidad.marker = marker;
        });

        setUnidades([...unidades]);
      }
    };

    loadGoogleMapsScript();
  }, []);

  // Función para centrar el mapa en la unidad seleccionada
  const handleCardClick = (unidad) => {
    if (unidad.marker) {
      googleMapInstance.current.map.setCenter(unidad.marker.getPosition());
      googleMapInstance.current.map.setZoom(15);
    }
  };

  return (
    <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
      <div ref={mapRef} style={{ width: '100%', height: '75%' }}></div>
      
      {/* Listado de unidades con scroll horizontal */}
      <div className='flex containerScroll h-[16%] p-2 overflow-x-auto overflow-y-hidden bg-gray-900'>
        {unidades.map(unidad => (
          <Card 
            key={unidad.id}
            onClick={() => handleCardClick(unidad)} 
            style={{
              minWidth: '250px',
              marginRight: '15px',
              cursor: 'pointer',
              transition: 'transform 0.3s',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
            }}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <CardContent>
              <Typography variant="h6">Placa: {unidad.placa}</Typography>
              <Typography variant="body2">Latitud: {unidad.lat}</Typography>
              <Typography variant="body2">Longitud: {unidad.lng}</Typography>
              <Typography variant="body2">Fecha y Hora: {unidad.fecha.toLocaleString('es-ES')}</Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};
